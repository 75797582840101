import { RoutesMap } from '@/routers/map';
import { FilterData, FilterSelector } from './reducers';
import * as types from './types';

export type FiltersData = {
  type: RoutesMap;
  views: FilterData[];
};

export const filterRequest = (data?: RoutesMap) => {
  return {
    type: types.FILTER_REQUEST,
    data,
  };
};

export const filterSuccess = (data: FiltersData) => {
  return {
    type: types.FILTER_SUCCESS,
    payload: { data },
  };
};

export const filterFailure = () => {
  return {
    type: types.FILTER_FAILURE,
  };
};

export const setMetricsType = (type: RoutesMap) => ({
  type: types.SET_METRICS_TYPE,
  payload: { type },
});
export const resetMetrics = () => ({
  type: types.RESET_METRICS,
});

export const updateSelectorFilters = (data: FilterSelector) => ({
  type: types.UPDATE_SELECTED_FILTER,
  payload: { data },
});

const filtersActions = {
  filterRequest,
  filterSuccess,
  filterFailure,
  setMetricsType,
  resetMetrics,
  updateSelectorFilters,
};

export default filtersActions;
