import React from 'react';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MenuIcon from '@mui/icons-material/Menu';

import { logo } from '@/lib/constants/imgs';

import { LogoContent } from './styles';
import Logo from '../Logo';
import { filtersActions, authActions } from '@/store/actions';
import { authSelectors } from '@/store/selectors';
import { RoutesMap } from '@/routers/map';
import Strings from '@/lib/constants/values/strings.json';

const Header: React.FC = () => {
  const user = useSelector(authSelectors.getUser);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const directHandle = () => {
    dispatch(filtersActions.resetMetrics());
    navigate(RoutesMap.BASE);
  };
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed">
        <Toolbar
          sx={{
            pr: '60px',
          }}
        >
          <IconButton edge="start" color="inherit" aria-label="open drawer">
            <MenuIcon />
          </IconButton>
          <LogoContent>
            <Logo
              onClick={directHandle}
              size={36}
              src={logo}
              alt="Logo Dei-Brand"
              loading="lazy"
              style={{ cursor: 'pointer' }}
            />
          </LogoContent>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ textTransform: 'uppercase' }}>
              {user.username.length ? user.username.charAt(0) : ''}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => dispatch(authActions.logout())}>
              {Strings.signOut}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
