import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactSpeedometer from 'react-d3-speedometer';
import IntervalChip from '../IntervalChip';
import Logo from '../Logo';
import StyledTableRow from '../StyledTableRow';
import BorderLinearProgress from '../BorderLinearProgress';
import { useSelector } from 'react-redux';
import { deiRankingSelectors } from '@/store/ducks/deiRanking';
import { BrandRanking } from '@/lib/models/deiBrandRankings';
import { normalise } from '@/lib/utils/functions';
import { useBrandRankingMax } from '@/hooks';
import { authSelectors } from '@/store/selectors';

const CardDeiBrandRankingTable: React.FC = () => {
  const brandRanking = useSelector(
    deiRankingSelectors.getBrand
  ) as BrandRanking;
  const logo = useSelector(authSelectors.getLogo);
  const [width, setWidth] = useState(0);
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);
  useLayoutEffect(() => {
    if (medium && !small) {
      setWidth(500);
    } else {
      setWidth(ref.current.offsetWidth);
    }
  }, [medium, small]);

  const maxMemo = useBrandRankingMax(brandRanking);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
              scope="row"
              colSpan={3}
            >
              <Grid container columnSpacing={2} paddingX="16px">
                <Grid
                  item
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="row"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Logo src={logo} size={55} />
                    <Typography
                      color="text.primary"
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        marginLeft: 4,
                      }}
                    >
                      {brandRanking?.rank}
                    </Typography>
                    <IntervalChip
                      sx={{
                        marginLeft: 1,
                      }}
                      step={brandRanking?.step}
                    />
                  </Box>
                </Grid>
                <Grid ref={ref} item xs={6} sm={6} md={6} lg={6}>
                  <ReactSpeedometer
                    height={width - width / 2}
                    width={width - width / 5}
                    needleHeightRatio={0.6}
                    maxSegmentLabels={2}
                    segments={1000}
                    value={brandRanking?.score}
                    minValue={0}
                    maxValue={200}
                    needleColor="#EB5757"
                    ringWidth={30}
                    startColor="#F85959"
                    endColor="#59CAA2"
                    textColor="#000"
                    valueTextFontSize="28px"
                    labelFontSize={'20px'}
                    paddingVertical={10}
                    forceRender
                    paddingHorizontal={5}
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <StyledTableRow>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
              scope="row"
            >
              DIVERSITY PILAR
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
              scope="row"
            >
              RANK
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
              scope="row"
            >
              SCORE
            </TableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {brandRanking?.diversitiesPilar?.map((row, key) => (
            <StyledTableRow key={key}>
              <TableCell scope="row" sx={{ fontWeight: 300 }}>
                {row.name}
              </TableCell>
              <TableCell scope="row">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Typography sx={{ fontWeight: 700 }}>{row.rank}</Typography>
                  {row.step !== 0 && (
                    <IntervalChip step={row.step} sx={{ marginLeft: 3.25 }} />
                  )}
                </Box>
              </TableCell>
              <TableCell scope="row">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    minWidth: '150px',
                  }}
                >
                  <BorderLinearProgress
                    sx={{
                      marginLeft: 3,
                    }}
                    variant="determinate"
                    value={
                      maxMemo > 0 ? normalise(row.score, maxMemo) : row.score
                    }
                  />
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      marginLeft: 1,
                      fontWeight: 700,
                    }}
                  >
                    {row.score}
                  </Typography>
                </Box>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CardDeiBrandRankingTable;
