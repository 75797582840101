import React from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import history from '@/lib/utils/history';

type EnterpriseNameHeaderProps = {
  title?: string;
};

const EnterpriseNameHeader: React.FC<EnterpriseNameHeaderProps> = (
  props: EnterpriseNameHeaderProps
) => {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'primary.contrastText',
        padding: '13px 34px',
        height: 45,
        marginTop: '60px',
        alignItems: 'center',
        borderBottom: '1px solid #E5E5E5',
      }}
    >
      <ArrowBackIcon
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => history.back()}
      />
      <Typography
        variant="h6"
        component="h1"
        color="secondary"
        sx={{
          fontWeight: '400',
          marginLeft: 3.125,
          fontSize: 18,
        }}
      >
        {props.title}
      </Typography>
    </Box>
  );
};

export default EnterpriseNameHeader;
