import produce from 'immer';
import * as types from './types';

import { BrandRanking } from '@/lib/models/deiBrandRankings';

import {
  DEI_RANKING_FAILURE,
  DEI_RANKING_REQUEST,
  DEI_RANKING_SUCCESS,
} from './types';

type DeiRankingState = {
  data: BrandRanking[];
  loading: boolean;
  brandCurrent: BrandRanking;
};

const INITIAL_STATE: DeiRankingState = {
  data: [],
  loading: false,
  brandCurrent: null,
};

const deiRankingReducers = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case DEI_RANKING_REQUEST: {
        draft.loading = true;
        break;
      }
      case DEI_RANKING_SUCCESS: {
        draft.loading = false;
        draft.data = action.payload.data;
        draft.brandCurrent = action.payload.data[0];
        break;
      }
      case DEI_RANKING_FAILURE: {
        draft.loading = false;
        break;
      }
      case types.CHOOSE_BRAND: {
        draft.brandCurrent = action.payload.data;
        break;
      }
      case types.RESET_BRAND: {
        draft.brandCurrent = null;
        draft.data = [];
        draft.loading = false;
        break;
      }
      default:
    }
  });
};

export default deiRankingReducers;
