import { RootState } from '@/store';
export const getToken = (state: RootState) => state.auth.token;
export const getTemplateIsLoaded = (state: RootState) =>
  state.auth.template_is_loaded;
export const getLoading = (state: RootState) => state.auth.loading;
export const getUser = (state: RootState) => state.auth.user;
export const getLogo = (state: RootState) => state.auth.logo;

const AuthSelectors = {
  getToken,
  getLoading,
  getTemplateIsLoaded,
  getUser,
  getLogo,
};

export default AuthSelectors;
