import translate from './strings.json';
import {
  transparenceTableImg,
  myReportsImg,
  alertManagementImg,
  deiRankingImg,
} from '@/lib/constants/imgs';

import { RoutesMap } from '@/routers/map';

const homeCardConfig = {
  [translate.Home.transparenceTable]: {
    title: translate.Home.transparenceTable,
    url: transparenceTableImg,
    route: RoutesMap.TRANSPARENCE_TABLE,
  },
  [translate.Home.myReports]: {
    title: translate.Home.myReports,
    url: myReportsImg,
    route: RoutesMap.MY_REPORTS,
  },
  [translate.Home.alertManagement]: {
    title: translate.Home.alertManagement,
    url: alertManagementImg,
    width: '82.5px',
    route: RoutesMap.ALERT_MANAGEMENT,
  },
  [translate.Home.deiRanking]: {
    title: translate.Home.deiRanking,
    url: deiRankingImg,
    width: '80.78px',
    route: RoutesMap.DEI_Ranking,
  },
};

export default homeCardConfig;
