export enum RoutesMap {
  BASE = '/',
  LOGIN = '/login',
  TRANSPARENCE_TABLE = '/transparence-table',
  MY_REPORTS = '/my-reports',
  ALERT_MANAGEMENT = '/alert-management',
  ALERT_MANAGEMENT_NOTIFICATIONS = '/alert-management-notifications/:id/:days',
  ALERT_MANAGEMENT_NOTIFICATIONS_WITHOUT = '/alert-management-notifications',
  DEI_Ranking = '/dei-ranking',
  UPLOAD = '/upload',
}

export const RoutesNameHeader: string[] = [
  RoutesMap.ALERT_MANAGEMENT,
  RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS_WITHOUT,
  RoutesMap.TRANSPARENCE_TABLE,
  RoutesMap.DEI_Ranking,
  RoutesMap.MY_REPORTS,
];
export const NameHeader = {
  [RoutesMap.ALERT_MANAGEMENT]: 'Alert Management',
  [RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS_WITHOUT]: 'Alert Management',
  [RoutesMap.TRANSPARENCE_TABLE]: 'Transparence Table',
  [RoutesMap.DEI_Ranking]: 'DE&I Brand Index Ranking',
  [RoutesMap.MY_REPORTS]: 'My Reports',
};
