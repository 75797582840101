import React, { ElementType, Fragment } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import DefaultLayout from '@/pages/layout/DefaultLayout';
import { RoutesMap } from './map';
import { useSelector } from 'react-redux';
import { authSelectors } from '@/store/selectors';
import DefaultUpload from '@/pages/layout/DefaultUpload';

type RouteWrapperProps = {
  isPrivate?: boolean;
  component: ElementType;
} & RouteProps;

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}: RouteWrapperProps) {
  const signed = useSelector(authSelectors.getToken);

  if (!signed && isPrivate) {
    return {
      ...rest,
      element: <Navigate to={RoutesMap.LOGIN} />,
    };
  }

  if (signed && !isPrivate) {
    return {
      ...rest,
      element: <Navigate to={RoutesMap.BASE} replace />,
    };
  }

  const Layout =
    rest.path !== '/upload' && signed
      ? DefaultLayout
      : rest.path === '/upload'
      ? DefaultUpload
      : Fragment;

  return {
    ...rest,
    element: (
      <Layout>
        <Component />
      </Layout>
    ),
  };
}
