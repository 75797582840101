import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import Strings from '@/lib/constants/values/strings.json';
import DropFile from '@/components/DropFile';
import { uploadImg } from '@/lib/constants/imgs';
import { uploadActions } from '@/store/actions';
import { uploadSelectors } from '@/store/selectors';
import template from '@/assets/files/template.csv';

const Upload: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(uploadSelectors.getLoading);
  const [file, setFile] = useState<any>({});

  const onSubmit = () => {
    dispatch(uploadActions.uploadTemplate(file));
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        component={Paper}
        elevation={6}
        square
        position="relative"
      >
        <Box
          sx={{
            marginTop: 12,
            mx: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4" color="primary">
            {Strings.uploadTitle}
          </Typography>
          <Grid container alignItems="center" mt={9}>
            <Grid item>
              <Avatar
                sx={{
                  bgcolor: 'primary.main',
                  width: '41px',
                  height: '41px',
                  mr: '17px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: '400',
                  }}
                >
                  1
                </Typography>
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {Strings.downloadDescription}
              </Typography>
            </Grid>
          </Grid>
          <Button variant="outlined" sx={{ mt: 4 }} href={template} download>
            {Strings.downloadTemplate}
          </Button>
          <Grid container alignItems="center" mt={9} mb={3}>
            <Grid item>
              <Avatar
                sx={{
                  bgcolor: 'primary.main',
                  width: '41px',
                  height: '41px',
                  mr: '17px',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: '400',
                  }}
                >
                  2
                </Typography>
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h6">{Strings.uploadDescription}</Typography>
            </Grid>
          </Grid>
          <DropFile
            callback={(val) => setFile(val)}
            loading={loading}
            uploadedFileName={file.name || ''}
            accept={{ 'text/*': ['.csv', '.xlsx'] }}
            onClose={() => setFile({})}
          />
          <Grid sx={{ width: '100%' }} textAlign="end">
            <Button
              variant="contained"
              sx={{ mt: 5, mb: 2, height: '56px', width: '121px' }}
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={25} /> : <>{Strings.upload}</>}
            </Button>
          </Grid>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        md={6}
        sx={{
          backgroundImage: `url(${uploadImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'right',
        }}
      />
    </Grid>
  );
};

export default Upload;
