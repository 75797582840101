import { useMemo } from 'react';
import { NameHeader, RoutesNameHeader } from '@/routers/map';
import { useLocation } from 'react-router-dom';

const useRoutesNameHeader = () => {
  const location = useLocation();

  return useMemo(() => {
    const fields = location.pathname.split('/');

    if (RoutesNameHeader.includes(`/${fields[1]}`)) {
      return {
        title: NameHeader[`/${fields[1]}`],
        isVisible: true,
        height: '209px',
      };
    }

    return {
      isVisible: false,
      height: '164px',
    };
  }, [location]);
};

export default useRoutesNameHeader;
