import React from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Grid,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { filtersSelectors } from '@/store/selectors';
import { FilterData, FilterType } from '@/store/ducks/filters/reducers';
import { filtersActions } from '@/store/actions';
import FiltersLoading from '../loadings/FiltersLoading';

export default function Filters() {
  const views: FilterData[] = useSelector(filtersSelectors.getFilters);
  const numbersFilters = useSelector(filtersSelectors.getNumbersFilters);
  const selectedFilters = useSelector(filtersSelectors.getSelectedFilters);
  const loading = useSelector(filtersSelectors.getLoading);
  const dispatch = useDispatch();

  const selecterFilterHandle = (
    event: SelectChangeEvent | null,
    type: FilterType
  ) => {
    dispatch(
      filtersActions.updateSelectorFilters({
        choose: event.target.value,
        type,
      })
    );
  };

  return (
    <div style={{ display: 'flex', flex: 1, width: '100%' }}>
      <Grid container flexDirection="row" justifyContent="flex-end">
        {loading ? (
          <FiltersLoading numberFilters={numbersFilters} />
        ) : (
          views?.map((value) => (
            <Grid
              key={value.title}
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              mt={2.5}
              mr={1.5}
            >
              <FormControl
                sx={{
                  m: 1,
                  width: '100%',
                }}
              >
                <InputLabel id={`${value.title}-label`}>
                  {value.title}
                </InputLabel>
                <Select
                  labelId={`${value.title}-label`}
                  id="demo-simple-select-disabled"
                  label={value.title}
                  onChange={(event: SelectChangeEvent) =>
                    selecterFilterHandle(event, value.type)
                  }
                  value={
                    selectedFilters?.find(
                      (selected) => selected.type === value.type
                    )?.choose || ''
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {value.options.map((option, key) => (
                    <MenuItem key={key} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
}
