import { put, takeLatest, call } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as actions from './actions';
import * as transparenceTable from '@/services/transparenceTable';
import * as types from './types';
import Strings from '@/lib/constants/values/strings.json';

export function* transparenceTableRequest(payload: any) {
  // const { data } = payload;
  try {
    const response = yield call(transparenceTable.transparenceTableRequest);
    yield put(actions.updateTransparenceTableSuccess(response.data));
  } catch (error) {
    toast.error(Strings.errorApi);
    yield put(actions.updateTransparenceTableFailure());
  }
}

export default function* watchTransparenceTable() {
  yield takeLatest(types.TRANSPARENCE_TABLE_REQUEST, transparenceTableRequest);
}
