import React from 'react';
import { Avatar, Box, Typography, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { enterpriseLogo } from '@/lib/constants/imgs';
import Logo from '../Logo';
import Filters from '../Filters';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { authSelectors } from '@/store/selectors';

const EnterpriseHeader: React.FC = () => {
  const location = useLocation();
  const filters = useSelector((state: RootState) => state.filters);
  const md = useMediaQuery('(max-width: 900px)');
  const logo = useSelector(authSelectors.getLogo);
  const user = useSelector(authSelectors.getUser);
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'primary.contrastText',
        padding: '16px 40px',
        flexDirection: md ? 'column' : 'row',
        width: '100%',
        height: md ? 'auto' : 104,
        marginTop: `${location.pathname === '/' ? '60px' : '0'}`,
        alignItems: 'center',
        borderBottom: '1px solid #E5E5E5',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.04)',
      }}
    >
      {logo ? (
        <Logo
          size={71}
          src={logo || enterpriseLogo}
          alt="Logo Dei-Brand"
          loading="lazy"
        />
      ) : (
        <Avatar
          sx={{ textTransform: 'uppercase', width: '71px', height: '71px' }}
        >
          {user.username.length ? user.username.charAt(0) : ''}
        </Avatar>
      )}

      {location.pathname !== '/' || filters.show ? (
        <Filters />
      ) : (
        <Typography
          variant="h6"
          component="h1"
          color={'secondary'}
          sx={{
            fontStyle: 'normal',
            marginLeft: 3.125,
          }}
        >
          Hello,{' '}
          <Typography
            variant="h6"
            component="span"
            sx={{
              fontFamily: 'EYInterstate',
              fontWeight: '700',
              textTransform: 'capitalize',
            }}
          >
            {user.username}.
          </Typography>
        </Typography>
      )}
    </Box>
  );
};

export default EnterpriseHeader;
