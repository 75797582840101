import React from 'react';

import { Card, CardContent, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '@/routers/map';

type PaperCardProps = {
  title: string;
  url: string;
  width?: string | undefined;
  route: RoutesMap;
};

const PaperCard: React.FC = ({
  title,
  url,
  width = '75px',
  route,
}: PaperCardProps) => {
  const navigate = useNavigate();
  const xd = useMediaQuery('(max-width: 529px)');

  const directHandle = () => {
    navigate(route);
  };
  return (
    <Card
      sx={{
        minWidth: xd ? '100%' : 212,
        maxHeight: '261px',
        height: '100%',
        marginRight: xd ? '0px' : '23px',
        cursor: 'pointer',
        marginBottom: xd ? '10px' : 0,
      }}
      onClick={directHandle}
    >
      <CardContent
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: '42px 35px',
          paddingTop: '60',
          height: '100%',
        }}
      >
        <img src={url} width={width} height="90px" alt={`${title} Img`} />
        <Typography
          variant="body2"
          color="secondary"
          sx={{
            fontSize: '18px',
            marginTop: '19px',
            maxWidth: '95px',
            fontWeight: 300,
          }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PaperCard;
