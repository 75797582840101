import React, { useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import PaperCard from '@/components/PaperCard';
import translation from '@/lib/constants/values/strings.json';
import homeCardConfig from '@/lib/constants/values/homeConfig';
import { useDispatch } from 'react-redux';
import { filtersActions } from '@/store/actions';

const Home: React.FC = () => {
  const xd = useMediaQuery('(max-width: 529px)');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filtersActions.resetMetrics());
  }, [dispatch]);

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexWrap: 'wrap',
        padding: xd ? '20px 20px' : '20px 30px',
      }}
    >
      {Object.values(translation.Home).map((value) => (
        <PaperCard key={value} {...homeCardConfig[value]} />
      ))}
    </Box>
  );
};

export default Home;
