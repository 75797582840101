import React from 'react';
import { Grid, Skeleton } from '@mui/material';

type FiltersLoadingProps = {
  numberFilters: number;
};

export default function FiltersLoading(props: FiltersLoadingProps) {
  return (
    <>
      {Array.from({ length: props.numberFilters }, () =>
        Math.floor(Math.random() * props.numberFilters)
      )?.map((_, key) => (
        <Grid key={key} item xs={12} sm={12} md={2} lg={2} mt={2.5} mr={1.5}>
          <Skeleton height={80} width="100%" />
        </Grid>
      ))}
    </>
  );
}
