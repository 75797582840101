import { put, takeLatest, call } from 'redux-saga/effects';
import * as actions from './actions';
import * as myReports from '@/services/myReports';
import * as types from './types';

export function* myReportsRequest(payload: any) {
  try {
    const { data } = payload;
    const response = yield call(myReports.myReportsRequest, data);

    yield put(actions.updateMyReportsSuccess(response.data));
  } catch (error) {
    yield put(actions.updateMyReportsFailure());
  }
}

export default function* watchMyReports() {
  yield takeLatest(types.MY_REPORTS_REQUEST, myReportsRequest);
}
