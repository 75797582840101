export const AUTH_REQUEST: string = 'auth/AUTH_REQUEST';
export const USER_LOGO: string = 'auth/USER_LOGO';
export const SET_TOKEN: string = 'auth/SET_TOKEN';
export const SET_TEMPLATE_IS_LOADED: string = 'auth/SET_TEMPLATE_IS_LOADED';
export const SET_USER: string = 'auth/SET_USER';
export const SET_LOADING: string = 'auth/SET_LOADING';
export const RESET_USER: string = 'auth/RESET_USER';
export const LOGOUT: string = 'auth/LOGOUT';

const AuthTypes = {
  AUTH_REQUEST,
  USER_LOGO,
  SET_TOKEN,
  SET_USER,
  SET_TEMPLATE_IS_LOADED,
  SET_LOADING,
  RESET_USER,
  LOGOUT,
};

export default AuthTypes;
