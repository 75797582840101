export const MY_REPORTS_REQUEST: string = 'myReports/MY_REPORTS_REQUEST';
export const MY_REPORTS_OVERALL_REQUEST: string =
  'myReports/MY_REPORTS_OVERALL_REQUEST';
export const MY_REPORTS_PLATFORM_REQUEST: string =
  'myReports/MY_REPORTS_PLATFORM_REQUEST';
export const MY_REPORTS_BREAKDOWN_SCORE_REQUEST: string =
  'myReports/MY_REPORTS_BREAKDOWN_SCORE_REQUEST';
export const MY_REPORTS_HEATMAP_REQUEST: string =
  'myReports/MY_REPORTS_HEATMAP_REQUEST';
export const MY_REPORTS_COMPETITOR_EVOLUTION_REQUEST: string =
  'myReports/MY_REPORTS_COMPETITOR_EVOLUTION_REQUEST';
export const MY_REPORTS_SOCIAL_MEDIA_FEELING_REQUEST: string =
  'myReports/MY_REPORTS_SOCIAL_MEDIA_FEELING_REQUEST';
export const MY_REPORTS_SOCIAL_MEDIA_OVERALL_REQUEST: string =
  'myReports/MY_REPORTS_SOCIAL_MEDIA_OVERALL_REQUEST';
export const MY_REPORTS_SUCCESS: string = 'myReports/MY_REPORTS_SUCCESS';
export const MY_REPORTS_OVERALL_SUCCESS: string =
  'myReports/MY_REPORTS_OVERALL_SUCCESS';
export const MY_REPORTS_PLATFORM_SUCCESS: string =
  'myReports/MY_REPORTS_PLATFORM_SUCCESS';
export const MY_REPORTS_BREAKDOWN_SCORE_SUCCESS: string =
  'myReports/MY_REPORTS_BREAKDOWN_SCORE_SUCCESS';
export const MY_REPORTS_HEATMAP_SUCCESS: string =
  'myReports/MY_REPORTS_HEATMAP_SUCCESS';
export const MY_REPORTS_COMPETITOR_EVOLUTION_SUCCESS: string =
  'myReports/MY_REPORTS_COMPETITOR_EVOLUTION_SUCCESS';
export const MY_REPORTS_SOCIAL_MEDIA_FEELING_SUCCESS: string =
  'myReports/MY_REPORTS_SOCIAL_MEDIA_FEELING_SUCCESS';
export const MY_REPORTS_SOCIAL_MEDIA_OVERALL_SUCCESS: string =
  'myReports/MY_REPORTS_SOCIAL_MEDIA_OVERALL_SUCCESS';
export const MY_REPORTS_FAILURE: string = 'myReports/MY_REPORTS_FAILURE';
export const MY_REPORTS_OVERALL_FAILURE: string =
  'myReports/MY_REPORTS_OVERALL_FAILURE';
export const MY_REPORTS_PLATFORM_FAILURE: string =
  'myReports/MY_REPORTS_PLATFORM_FAILURE';
export const MY_REPORTS_BREAKDOWN_SCORE_FAILURE: string =
  'myReports/MY_REPORTS_BREAKDOWN_SCORE_FAILURE';
export const MY_REPORTS_HEATMAP_FAILURE: string =
  'myReports/MY_REPORTS_HEATMAP_FAILURE';
export const MY_REPORTS_COMPETITOR_EVOLUTION_FAILURE: string =
  'myReports/MY_REPORTS_COMPETITOR_EVOLUTION_FAILURE';
export const MY_REPORTS_SOCIAL_MEDIA_FEELING_FAILURE: string =
  'myReports/MY_REPORTS_SOCIAL_MEDIA_FEELING_FAILURE';
export const MY_REPORTS_SOCIAL_MEDIA_OVERALL_FAILURE: string =
  'myReports/MY_REPORTS_SOCIAL_MEDIA_OVERALL_FAILURE';

const myReportsTypes = {
  MY_REPORTS_REQUEST,
  MY_REPORTS_OVERALL_REQUEST,
  MY_REPORTS_PLATFORM_REQUEST,
  MY_REPORTS_BREAKDOWN_SCORE_REQUEST,
  MY_REPORTS_HEATMAP_REQUEST,
  MY_REPORTS_COMPETITOR_EVOLUTION_REQUEST,
  MY_REPORTS_SOCIAL_MEDIA_FEELING_REQUEST,
  MY_REPORTS_SOCIAL_MEDIA_OVERALL_REQUEST,
  MY_REPORTS_SUCCESS,
  MY_REPORTS_OVERALL_SUCCESS,
  MY_REPORTS_PLATFORM_SUCCESS,
  MY_REPORTS_BREAKDOWN_SCORE_SUCCESS,
  MY_REPORTS_HEATMAP_SUCCESS,
  MY_REPORTS_COMPETITOR_EVOLUTION_SUCCESS,
  MY_REPORTS_SOCIAL_MEDIA_FEELING_SUCCESS,
  MY_REPORTS_SOCIAL_MEDIA_OVERALL_SUCCESS,
  MY_REPORTS_FAILURE,
  MY_REPORTS_OVERALL_FAILURE,
  MY_REPORTS_PLATFORM_FAILURE,
  MY_REPORTS_BREAKDOWN_SCORE_FAILURE,
  MY_REPORTS_HEATMAP_FAILURE,
  MY_REPORTS_COMPETITOR_EVOLUTION_FAILURE,
  MY_REPORTS_SOCIAL_MEDIA_FEELING_FAILURE,
  MY_REPORTS_SOCIAL_MEDIA_OVERALL_FAILURE,
};

export default myReportsTypes;
