import produce from 'immer';

import { MyReportsModel } from '@/lib/models/myReports';

import * as types from './types';

type MyReportsState = {
  data: MyReportsModel;
  overallLoading: boolean;
  heatMapLoading: boolean;
  breakdownScoreLoading: boolean;
  plataformLoading: boolean;
  competitorEvolutionLoading: boolean;
  socialMediaPillarLoading: boolean;
  socialMediaOverallLoading: boolean;
};

const INITIAL_STATE: MyReportsState = {
  data: {
    overall: null,
    heatMap: null,
    breakdownScore: null,
    plataform: null,
    competitorEvolution: null,
    socialMediaPillar: null,
    socialMediaOverall: null,
  },
  overallLoading: false,
  heatMapLoading: false,
  breakdownScoreLoading: false,
  plataformLoading: false,
  competitorEvolutionLoading: false,
  socialMediaPillarLoading: false,
  socialMediaOverallLoading: false,
};

const myReportsReducers = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.MY_REPORTS_REQUEST: {
        draft.overallLoading = true;
        draft.heatMapLoading = true;
        draft.breakdownScoreLoading = true;
        draft.plataformLoading = true;
        draft.competitorEvolutionLoading = true;
        draft.socialMediaPillarLoading = true;
        draft.socialMediaOverallLoading = true;
        break;
      }
      case types.MY_REPORTS_SUCCESS: {
        draft.data = action.payload.data;
        draft.overallLoading = false;
        draft.heatMapLoading = false;
        draft.breakdownScoreLoading = false;
        draft.plataformLoading = false;
        draft.competitorEvolutionLoading = false;
        draft.socialMediaPillarLoading = false;
        draft.socialMediaOverallLoading = false;
        break;
      }
      case types.MY_REPORTS_FAILURE: {
        draft.overallLoading = false;
        draft.heatMapLoading = false;
        draft.breakdownScoreLoading = false;
        draft.plataformLoading = false;
        draft.competitorEvolutionLoading = false;
        draft.socialMediaPillarLoading = false;
        draft.socialMediaOverallLoading = false;
        break;
      }
      default:
    }
  });
};

export default myReportsReducers;
