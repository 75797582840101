import React, { useRef, useCallback } from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Logo from '../Logo';
import Strings from '@/lib/constants/values/strings.json';
import { uploadIcon } from '@/lib/constants/imgs';
import { uploadSelectors } from '@/store/selectors';
import { ProgressType } from '@/store/ducks/upload/reducers';
import {
  Container,
  DropzoneContainer,
  Wrapper,
  FileContainer,
  BorderLinearProgress,
} from './styles';

function bytesToMegaBytes(bytes: number, roundTo: number) {
  const converted = bytes / (1024 * 1024);
  return roundTo ? converted.toFixed(roundTo) : converted;
}

interface Accept {
  [key: string]: string[];
}

interface DropFilesProps {
  callback: Function;
  uploadedFileName: string;
  accept: Accept;
  onClose: Function;
  loading: boolean;
}

const DropFile: React.FC<DropFilesProps> = ({
  callback,
  uploadedFileName,
  accept,
  onClose,
  loading,
}) => {
  const dropzoneRef = useRef<DropzoneRef>();
  const progress: ProgressType = useSelector(uploadSelectors.getProgress);

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();
        const blob = new Blob([file], { type: file.type });
        const localUrl = window.URL.createObjectURL(blob);
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          callback(file, localUrl);
        };
        reader.readAsText(blob);
      });
    },
    [callback]
  );

  const renderMessage = (isDragReject: boolean) => {
    if (isDragReject) {
      return (
        <Typography component="p" color="secondary">
          {Strings.unsupportedFile}
        </Typography>
      );
    }
  };

  return (
    <Container>
      <Wrapper>
        {uploadedFileName !== '' ? (
          <FileContainer>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography
                  component="span"
                  sx={{ fontWeight: 300, fontSize: '1rem' }}
                >
                  {uploadedFileName}
                </Typography>
              </Grid>
              <Grid item>
                <CloseIcon
                  sx={{ color: '#F85959', marginTop: '5px', cursor: 'pointer' }}
                  onClick={() => onClose()}
                />
              </Grid>
            </Grid>
          </FileContainer>
        ) : null}
        <Dropzone
          useFsAccessApi={false}
          ref={dropzoneRef}
          accept={accept}
          onDropAccepted={onDrop}
        >
          {({ getRootProps, getInputProps, isDragReject }) => (
            <>
              {uploadedFileName === '' ? (
                <DropzoneContainer {...getRootProps()}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mx: 4.8, my: 4, width: 'auto' }}
                  >
                    <Grid item>
                      <Logo
                        size={51}
                        src={uploadIcon}
                        alt="upload-icon"
                        loading="lazy"
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h4"
                        color="secondary"
                        sx={{ fontWeight: 300, fontSize: '1.5rem', ml: '31px' }}
                      >
                        {Strings.dropzoneDescription}
                      </Typography>
                    </Grid>
                  </Grid>

                  <input {...getInputProps()} />
                  {renderMessage(isDragReject)}
                </DropzoneContainer>
              ) : null}
            </>
          )}
        </Dropzone>
      </Wrapper>
      <Grid container justifyContent="space-between">
        <Grid item sx={{ mt: 3 }}>
          <Button variant="outlined" onClick={openDialog}>
            <AddIcon sx={{ mr: '10px', mt: '-2px' }} />
            {Strings.selectFolder}
          </Button>
        </Grid>
        {loading ? (
          <Grid item sx={{ mt: 3 }}>
            <BorderLinearProgress
              variant="determinate"
              value={progress.percentage}
              color="success"
            />
            <Typography
              component="p"
              color="secondary"
              sx={{ fontWeight: 300, fontSize: '0.75rem', textAlign: 'right' }}
            >
              {`${bytesToMegaBytes(progress.loaded, 2)}/${bytesToMegaBytes(
                progress.total,
                2
              )} MB`}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
};

DropFile.defaultProps = {
  callback: () => {},
  uploadedFileName: '',
  accept: { 'text/*': ['.csv', '.xlsx'] },
  onClose: () => {},
  loading: false,
};

export default React.memo(DropFile);
