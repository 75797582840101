import {
  SET_TOKEN,
  SET_USER,
  SET_LOADING,
  RESET_USER,
  SET_TEMPLATE_IS_LOADED,
  USER_LOGO,
} from './types';

export type User = {
  _id: string;
  company_id: string;
  username: string;
};

export type AuthType = {
  token: string;
  user: User;
  loading: boolean;
  template_is_loaded: boolean;
  logo: string;
};

const userDefault: User = {
  _id: '',
  company_id: '',
  username: '',
};

const initialState: AuthType = {
  token: '',
  user: userDefault,
  loading: false,
  template_is_loaded: true,
  logo: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case SET_TEMPLATE_IS_LOADED:
      return {
        ...state,
        template_is_loaded: action.data,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.data,
      };
    case USER_LOGO:
      return {
        ...state,
        logo: action.data,
      };
    case SET_USER:
      return {
        ...state,
        user: action.data,
      };
    case RESET_USER:
      return {
        ...state,
        user: userDefault,
        token: '',
        loading: false,
        template_is_loaded: true,
        logo: '',
      };
    default:
      return state;
  }
};

export default authReducer;
