import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import CardDeiBrandRankingsTable from '@/components/CardDeiBrandRankingsTable';
import CardDeiBrandRankingTable from '@/components/CardDeiBrandRankingTable';
import { useDispatch } from 'react-redux';
import { filtersActions } from '@/store/actions';
import { RoutesMap } from '@/routers/map';
import DeiBrandRankingLoading from '@/components/loadings/DeiBrandRankingLoading';
import DeiBrandRankingsLoading from '@/components/loadings/DeiBrandRankingsLoading';
import {
  deiRankingActions,
  deiRankingSelectors,
} from '@/store/ducks/deiRanking';
import { filtersSelectors } from '@/store/selectors';

import { BrandRanking } from '@/lib/models/deiBrandRankings';
import { useSelector } from 'react-redux';
import { FilterType } from '@/store/ducks/filters/reducers';

const DeiRanking: React.FC = () => {
  const loading = useSelector(deiRankingSelectors.getLoading);
  const selectedFilters = useSelector(filtersSelectors.getSelectedFilters);

  const data = useSelector(
    deiRankingSelectors.getDeiRankingData
  ) as BrandRanking[];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(filtersActions.filterRequest(RoutesMap.DEI_Ranking));
    dispatch(deiRankingActions.deiRankingRequest({}));
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(deiRankingActions.deiRankingRequest({}));
    };
    const interval = setInterval(() => {
      fetchData();
    }, 7200000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (selectedFilters.length > 0) {
      let data = {};

      const timelineOption = selectedFilters.find(
        (selected) => selected.type === FilterType.TIMELINE
      );
      if (timelineOption) {
        data = { days: timelineOption.choose };
      }
      dispatch(deiRankingActions.deiRankingRequest(data));
    } else {
      dispatch(filtersActions.filterRequest(RoutesMap.ALERT_MANAGEMENT));
    }
  }, [dispatch, selectedFilters]);

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        padding: '10px 30px 30px 30px',
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} mt={2.5}>
          {loading ? (
            <DeiBrandRankingsLoading />
          ) : (
            <CardDeiBrandRankingsTable data={data} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} mt={2.5}>
          {loading ? <DeiBrandRankingLoading /> : <CardDeiBrandRankingTable />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeiRanking;
