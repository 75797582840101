import { put, takeLatest, call } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as actions from './actions';
import * as auth from '@/services/auth';
import * as types from './types';
import Strings from '@/lib/constants/values/strings.json';

export function* authRequest(payload: any) {
  const { data } = payload;
  yield put(actions.setLoading());
  try {
    const response = yield call(auth.authRequest, data);

    if (response.data.user) {
      yield put(actions.setUser(response.data.user));
      yield put(actions.setToken(response.data.token));
      yield put(actions.setTemplateIsLoaded(response.data.template_is_loaded));
      const responseLogo = yield call(auth.userGetLogo, response.data.token);
      yield put(actions.userLogo(responseLogo.data));
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(Strings.errorApi);
  }

  yield put(actions.setLoading());
}

export function* logout() {
  yield put(actions.resetUser());
}

export default function* watchUserAuthentication() {
  yield takeLatest(types.AUTH_REQUEST, authRequest);
  yield takeLatest(types.LOGOUT, logout);
}
