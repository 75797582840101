import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { CompetitorEvolutionProps } from '@/lib/models/charts';

import Strings from '@/lib/constants/values/strings.json';
import { colorsEvolution } from '@/lib/utils/functions';

const CardCompetitorEvolution = ({ data }: CompetitorEvolutionProps) => {
  const renderColorfulLegendText = (value: string) => {
    return (
      <span
        style={{
          color: '#000',
          textTransform: 'capitalize',
          marginRight: '15px',
          fontWeight: '300',
          fontSize: '0.75rem',
        }}
      >
        {value}
      </span>
    );
  };
  return (
    <Card
      sx={{
        height: '100%',
      }}
    >
      <CardContent
        sx={{
          padding: '24px 27px',
          minHeight: '369px',
          height: '100%',
        }}
      >
        <Typography variant="subtitle1">
          {Strings.charts.titleCompetitorEvolution}
        </Typography>
        {data && (
          <Grid container alignItems="center" justifyContent="center" mt="20px">
            <Grid item xs={12} sm={12} md={12}>
              <ResponsiveContainer minHeight={'270px'} height="100%">
                <AreaChart
                  data={data}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid vertical={false} stroke="#E5E5E5" />
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#767676',
                    }}
                  />
                  <YAxis
                    type="number"
                    domain={[0, 100]}
                    ticks={[0, 50, 100, 150, 200]}
                    interval={0}
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#767676',
                    }}
                  />
                  <Tooltip />
                  {data[0]?.brands.map((brand, key) => {
                    return (
                      <Area
                        key={key}
                        type="monotone"
                        name={brand.name}
                        dataKey={`brands[${key}].value`}
                        stroke={colorsEvolution[key].primary}
                        fill={colorsEvolution[key].primary}
                        fillOpacity={0.3}
                      />
                    );
                  })}
                  <Legend
                    verticalAlign="bottom"
                    formatter={renderColorfulLegendText}
                    iconType="square"
                    iconSize={15}
                    align="center"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default CardCompetitorEvolution;
