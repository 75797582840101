export const TRANSPARENCE_TABLE_REQUEST: string =
  'tranparenceTable/TRANSPARENCE_TABLE_REQUEST';
export const TRANSPARENCE_TABLE_SUCCESS: string =
  'tranparenceTable/TRANSPARENCE_TABLE_SUCCESS';
export const TRANSPARENCE_TABLE_FAILURE: string =
  'tranparenceTable/TRANSPARENCE_TABLE_FAILURE';

const tranparenceTableTypes = {
  TRANSPARENCE_TABLE_REQUEST,
  TRANSPARENCE_TABLE_SUCCESS,
  TRANSPARENCE_TABLE_FAILURE,
};

export default tranparenceTableTypes;
