import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from './lib/utils/history';
import { store, persistor } from './store';

import Routes from './routers';
import theme from './styles/theme';
import GlobalStyles from './styles/global';
import { CssBaseline, ThemeProvider } from '@mui/material';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HistoryRouter history={history}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
          <GlobalStyles />
          <Toaster position="top-left" reverseOrder={true} />
        </HistoryRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
