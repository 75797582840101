import * as types from './types';
import { ProgressType } from './reducers';

export const uploadTemplate = (file: any) => ({
  type: types.UPLOAD_TEMPLATE,
  file,
});

export const setLoading = () => ({
  type: types.SET_LOADING,
});

export const setProgress = (data: ProgressType) => ({
  type: types.SET_PROGRESS,
  data,
});

export const resetProgress = () => ({
  type: types.RESET_PROGRESS,
});

const UploadActions = {
  uploadTemplate,
  setLoading,
  setProgress,
  resetProgress,
};

export default UploadActions;
