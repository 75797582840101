import styled from 'styled-components';

type LogoProps = {
  size: number;
};

export default styled.img<LogoProps>`
  height: ${(props) => (props.size ? props.size : 36)}px;
  width: ${(props) => (props.size ? props.size : 36)}px;
`;
