import { RootState } from '@/store';
export const getLoading = (state: RootState) => state.upload.loading;
export const getProgress = (state: RootState) => state.upload.progress;

const UploadSelectors = {
  getLoading,
  getProgress,
};

export default UploadSelectors;
