import React from 'react';
import { Grid, Skeleton } from '@mui/material';

export default function AlertManagementNotificationsLoading() {
  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} mt={2.5}>
          <Skeleton
            sx={{
              minHeight: '369px',
              transform: 'unset',
            }}
            width="100%"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} mt={2.5}>
          <Skeleton
            sx={{
              minHeight: '369px',
              transform: 'unset',
            }}
            width="100%"
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2.5}>
          <Skeleton
            sx={{
              minHeight: '426px',
              transform: 'unset',
            }}
            width="100%"
          />
        </Grid>
      </Grid>
    </>
  );
}
