import produce from 'immer';

import { RoutesMap } from '@/routers/map';
import {
  FILTER_FAILURE,
  FILTER_REQUEST,
  FILTER_SUCCESS,
  RESET_METRICS,
  SET_METRICS_TYPE,
  UPDATE_SELECTED_FILTER,
} from './types';

export enum FilterType {
  CHANNEL = 'channel',
  BRANDS = 'brands',
  POST_TYPE = 'postType',
  CATEGORIES = 'categories',
  COMPETITOR = 'competitor',
  SOCIAL_MEDIA = 'socialMedia',
  TIMELINE = 'timeline',
  LOCATION = 'location',
}

export type Option = {
  name: string;
  value: string;
};

export type FilterData = {
  title: string;
  type: FilterType;
  options?: Option[];
  date?: Date;
};

export type FilterSelector = {
  type: FilterType;
  choose: string;
};

export type FiltersState = {
  loading: boolean;
  numberLoading: number;
  show: boolean;
  type: RoutesMap;
  views: FilterData[];
  selectors: FilterSelector[];
};

const initialState: FiltersState = {
  loading: false,
  numberLoading: 0,
  show: false,
  type: null,
  views: [],
  selectors: [],
};

export const filterMetrics = {
  [RoutesMap.TRANSPARENCE_TABLE]: 5,
  [RoutesMap.MY_REPORTS]: 2,
  [RoutesMap.ALERT_MANAGEMENT]: 1,
  [RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS]: 1,
  [RoutesMap.DEI_Ranking]: 5,
};

const filtersReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case FILTER_REQUEST: {
        draft.loading = true;
        draft.numberLoading = filterMetrics[action.data];
        break;
      }
      case FILTER_SUCCESS: {
        draft.numberLoading = 0;
        draft.loading = false;
        draft.type = action.payload.data.type;
        draft.views = action.payload.data.views;
        draft.show = true;
        break;
      }
      case FILTER_FAILURE: {
        draft.numberLoading = 0;
        draft.loading = false;
        break;
      }
      case SET_METRICS_TYPE: {
        draft.type = action.payload.type;
        draft.views = [];
        draft.show = true;
        break;
      }
      case UPDATE_SELECTED_FILTER: {
        draft.selectors = draft.selectors.filter(
          (value) => value.type !== action.payload.data.type
        );
        draft.selectors.push(action.payload.data);
        break;
      }
      case RESET_METRICS: {
        draft.numberLoading = 0;
        draft.selectors = [];
        draft.show = false;
        draft.type = null;
        draft.views = [];
        break;
      }
      default:
    }
  });
};

export default filtersReducer;
