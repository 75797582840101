import api from './api';

export const transparenceTableRequest = () =>
  api.get('transparence-table/filters');
export const getPeriodicityFilterValues = () =>
  api.get('post/getPeriodicityFilterValues');
export const getBrandFilter = () => api.get('brand/get-company-brands');
export const myReportsRequest = () => api.get('my-reports/filters');
export const alertManagementRequest = () => api.get('alert-management/filters');
export const deiRankingRequest = () => api.get('dei-ranking/filters');
