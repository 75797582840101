import { createGlobalStyle } from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0
  }
  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100vh;
  }
  body {
    background-color: #F6F6F6;
    color: #646464 !important;
    -webkit-font-smoothing: antialiased !important;
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
  p, h1, h2 {
    text-align: start;
  }
`;
