import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from '@mui/material';
import {
  BrandRanking,
  CardDeiBrandRankingsTableProps,
} from '@/lib/models/deiBrandRankings';

import translate from '@/lib/constants/values/strings.json';
import IntervalChip from '../IntervalChip';
import StyledTableRow from '../StyledTableRow';
import BorderLinearProgress from '../BorderLinearProgress';
import { deiRankingActions } from '@/store/ducks/deiRanking';
import { useBrandRankingsMax } from '@/hooks';
import { normalise } from '@/lib/utils/functions';

const CardDeiBrandRankingsTable: React.FC<CardDeiBrandRankingsTableProps> = (
  props
) => {
  const dispatch = useDispatch();
  const max = useBrandRankingsMax(props.data);
  const handleChooseBrand = (data: BrandRanking) => {
    dispatch(deiRankingActions.chooseBrand(data));
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
            >
              {translate.brandRanking.BrandRankingTitle}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, key) => (
            <StyledTableRow
              key={key}
              hover
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => handleChooseBrand(row)}
            >
              <TableCell component="th" scope="row">
                <Grid container columnSpacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography
                      component="p"
                      sx={{ display: 'flex', fontWeight: 700 }}
                    >
                      {row.rank}{' '}
                      <Typography
                        sx={{
                          fontWeight: 300,
                          marginLeft: 4,
                          color: 'secondary.main',
                        }}
                        component="span"
                      >
                        {row.brand}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      {row.step !== 0 && <IntervalChip step={row.step} />}
                      <BorderLinearProgress
                        sx={{
                          marginLeft: 3,
                        }}
                        variant="determinate"
                        value={max > 0 ? normalise(row.score, max) : row.score}
                      />
                      <Typography
                        variant="body2"
                        color="primary"
                        sx={{
                          marginLeft: 1,
                          fontWeight: 700,
                        }}
                      >
                        {row.score}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CardDeiBrandRankingsTable;
