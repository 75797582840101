import axios, { AxiosInstance } from 'axios';
import { store } from '@/store';

const api: AxiosInstance = axios.create({
  baseURL: "https://beyondlabs-deibrandindex.azurewebsites.net/api/",
  headers: {
    'Content-type': 'application/json',
  },
});

api.interceptors.request.use(function (config) {
  const token = store.getState().auth.token;
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default api;
