import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import CardHeatmap from '@/components/CardHeatmap';
import CardSocialMediaPillar from '@/components/CardSocialMediaPillar';
import CardSocialMediaOverall from '@/components/CardSocialMediaOverall';
import CardBreakdownScore from '@/components/CardBreakdownScore';
import CardPlatformComparison from '@/components/CardPlatformComparison';
import CardOverall from '@/components/CardOverall';
import CardCompetitorEvolution from '@/components/CardCompetitorEvolution';
import { useDispatch } from 'react-redux';
import { filtersActions } from '@/store/actions';
import { RoutesMap } from '@/routers/map';
import { myReportsActions, myReportsSelectors } from '@/store/ducks/myReports';
import { useSelector } from 'react-redux';
import { MyReportsModel } from '@/lib/models/myReports';
import MyReportsLoading from '@/components/loadings/MyReportsLoading';
import CardBlank from '@/components/CardBlank';
import { filtersSelectors } from '@/store/selectors';
import { FilterType } from '@/store/ducks/filters/reducers';

const MyReports: React.FC = () => {
  const overallLoading = useSelector(myReportsSelectors.getOverallLoading);
  const heatMapLoading = useSelector(myReportsSelectors.getPlatformLoading);
  const breakdownScoreLoading = useSelector(
    myReportsSelectors.getBreakdownScoreLoading
  );
  const platformLoading = useSelector(
    myReportsSelectors.getHeatMapLoadingoading
  );
  const competitorEvolutionLoading = useSelector(
    myReportsSelectors.getCompetitorEvolutionLoading
  );
  const socialMediaFeelingLoading = useSelector(
    myReportsSelectors.getSocialMediaFeelingLoading
  );
  const socialMediaOverallLoading = useSelector(
    myReportsSelectors.getSocialMediaOverallLoading
  );

  const loading = useSelector(filtersSelectors.getLoading);
  const selectedFilters = useSelector(filtersSelectors.getSelectedFilters);

  const {
    breakdownScore,
    competitorEvolution,
    heatMap,
    overall,
    plataform: platform,
    socialMediaPillar: socialMediaFeeling,
    socialMediaOverall,
  } = useSelector(myReportsSelectors.getMyReportsData) as MyReportsModel;
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFilters.length > 0) {
      let data = {};
      const brandOption = selectedFilters.find(
        (selected) => selected.type === FilterType.BRANDS
      );
      const timelineOption = selectedFilters.find(
        (selected) => selected.type === FilterType.TIMELINE
      );
      if (brandOption) {
        data = { brand_id: brandOption.choose };
      }
      if (timelineOption) {
        data = { ...data, days: timelineOption.choose };
      }
      dispatch(myReportsActions.myReportsRequest(data));
    } else {
      dispatch(filtersActions.filterRequest(RoutesMap.MY_REPORTS));
    }
  }, [dispatch, selectedFilters]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(myReportsActions.myReportsRequest({}));
    };

    const interval = setInterval(() => {
      fetchData();
    }, 7200000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        padding: '10px 30px 30px 30px',
      }}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} mt={2.5}>
          {overallLoading || loading ? (
            <MyReportsLoading />
          ) : overall ? (
            <CardOverall value={overall.score} />
          ) : (
            <CardBlank />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} mt={2.5}>
          {platformLoading || loading ? (
            <MyReportsLoading />
          ) : (
            <CardPlatformComparison data={platform} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} mt={2.5}>
          {breakdownScoreLoading || loading ? (
            <MyReportsLoading />
          ) : (
            <CardBreakdownScore data={breakdownScore} />
          )}
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4} mt={2.5}>
          {heatMapLoading || loading ? (
            <MyReportsLoading />
          ) : (
            <CardHeatmap data={heatMap} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} mt={2.5}>
          {competitorEvolutionLoading || loading ? (
            <MyReportsLoading />
          ) : (
            <CardCompetitorEvolution data={competitorEvolution} />
          )}
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} mt={2.5}>
          {socialMediaFeelingLoading || loading ? (
            <MyReportsLoading />
          ) : (
            <CardSocialMediaPillar data={socialMediaFeeling} />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} mt={2.5}>
          {socialMediaOverallLoading || loading ? (
            <MyReportsLoading />
          ) : (
            <CardSocialMediaOverall data={socialMediaOverall} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyReports;
