import { put, takeLatest, call } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as actions from './actions';
import * as alertManagemen from '@/services/alertManagement';
import * as types from './types';
import Strings from '@/lib/constants/values/strings.json';

export function* alertManagementRequest() {
  try {
    const response = yield call(alertManagemen.alertManagementRequest);

    yield put(actions.updateAlertManagementSuccess(response.data));
  } catch (error) {
    toast.error(Strings.errorApi);
    yield put(actions.updateAlertManagementFailure());
  }
}

export function* alertManagementNotificationsRequest(action: any) {
  const id = action.payload.id;
  const days = action.payload.days;

  try {
    const response = yield call(
      alertManagemen.alertManagementNotificationsRequest,
      { id, days }
    );

    yield put(actions.updateAlertManagementNotificationsSuccess(response.data));
  } catch (error) {
    toast.error(Strings.errorApi);
  }
}

export default function* watchAlertManagement() {
  yield takeLatest(types.ALERT_MANAGEMENT_REQUEST, alertManagementRequest);
  yield takeLatest(
    types.ALERT_MANAGEMENT_NOTIFICATIONS_REQUEST,
    alertManagementNotificationsRequest
  );
}
