import {
  ALERT_MANAGEMENT_REQUEST,
  ALERT_MANAGEMENT_FAILURE,
  ALERT_MANAGEMENT_SUCCESS,
  ALERT_MANAGEMENT_NOTIFICATIONS_REQUEST,
  ALERT_MANAGEMENT_NOTIFICATIONS_SUCCESS,
} from './types';
import {
  AlertManagementItemModel,
  AlertManagementNotificationModel,
} from '@/lib/models/alertManagement';

export const alertManagementRequest = () => {
  return {
    type: ALERT_MANAGEMENT_REQUEST,
  };
};

export const updateAlertManagementSuccess = (
  data: AlertManagementItemModel[]
) => {
  return {
    type: ALERT_MANAGEMENT_SUCCESS,
    payload: { data },
  };
};

export const updateAlertManagementNotificationsSuccess = (
  data: AlertManagementNotificationModel
) => {
  return {
    type: ALERT_MANAGEMENT_NOTIFICATIONS_SUCCESS,
    payload: { data },
  };
};

export const alertManagementNotificationsRequest = (
  id: string,
  days: string
) => {
  return {
    type: ALERT_MANAGEMENT_NOTIFICATIONS_REQUEST,
    payload: { id, days },
  };
};

export const updateAlertManagementFailure = () => {
  return {
    type: ALERT_MANAGEMENT_FAILURE,
  };
};

const AlertManagementActions = {
  alertManagementRequest,
  updateAlertManagementSuccess,
  updateAlertManagementFailure,
  alertManagementNotificationsRequest,
  updateAlertManagementNotificationsSuccess,
};

export default AlertManagementActions;
