export const DEI_RANKING_REQUEST: string = 'deiRanking/DEI_RANKING_REQUEST';
export const DEI_RANKING_SUCCESS: string = 'deiRanking/DEI_RANKING_SUCCESS';
export const DEI_RANKING_FAILURE: string = 'deiRanking/DEI_RANKING_FAILURE';
export const CHOOSE_BRAND: string = 'deiRanking/CHOOSE_BRAND';
export const RESET_BRAND: string = 'deiRanking/RESET_BRAND';

const deiRankingTypes = {
  DEI_RANKING_REQUEST,
  DEI_RANKING_SUCCESS,
  DEI_RANKING_FAILURE,
  CHOOSE_BRAND,
  RESET_BRAND,
};

export default deiRankingTypes;
