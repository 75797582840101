import styled from 'styled-components';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
    align-items: center;
    border-style: dashed;
    border-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    border-width: 1px;
    justify-content: center;
    margin: 0
    min-height: 100px
    min-width: 180px";
    background-color: #F7F7F7;
`;

export const DropzoneContainer = styled.div`
    cursor: pointer;
    min-height: 100px
    min-width: 180px";
    background-color: #F7F7F7;
`;

export const FileContainer = styled.div`
  margin: 15px;
  padding: 8px 20px;
  background-color: #e5e5e5;
  border-radius: 50px;
`;

export const BorderLinearProgress = styled(LinearProgress)`
    height: 11px;
    width: 281px;
    border-radius: 50px;
    margin-bottom: 12px;
    &.${linearProgressClasses.root} {
        background-color: #FFF !important;
        border: 1px solid #58CAA1;
    },
`;
