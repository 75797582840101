import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  XAxis,
  ResponsiveContainer,
  YAxis,
  LabelList,
} from 'recharts';
import { SocialMediaFeelingProps } from '@/lib/models/charts';
import Strings from '@/lib/constants/values/strings.json';
import { tickFormatterMobile } from '@/lib/utils/functions';

const CardSocialMediaPillar = ({ data }: SocialMediaFeelingProps) => {
  const sm = useMediaQuery('(max-width: 529px)');
  const renderColorfulLegendText = (value: string) => {
    return (
      <span
        style={{
          color: '#000',
          textTransform: 'capitalize',
          marginRight: '15px',
          fontWeight: '300',
          fontSize: '0.75rem',
        }}
      >
        {value}
      </span>
    );
  };

  const valueAccessor =
    (attribute: string) =>
    ({ payload }) => {
      return payload[attribute];
    };

  return (
    <Card>
      <CardContent
        sx={{
          padding: '24px 27px',
          height: '488px',
        }}
      >
        <Typography variant="subtitle1">
          {Strings.charts.titleSocialMediaPillar}
        </Typography>
        {data && (
          <Grid container alignItems="center" justifyContent="center" mt="30px">
            <Grid item xs={12} sm={12} md={12}>
              <ResponsiveContainer width={'100%'} height={350}>
                <BarChart
                  data={data}
                  layout="vertical"
                  barSize={20}
                  margin={{ left: 0 }}
                >
                  <CartesianGrid stroke="#E5E5E5" horizontal={false} />
                  <XAxis
                    axisLine={false}
                    stroke="#E5E5E5"
                    type="number"
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#767676',
                    }}
                    dy={5}
                    tickFormatter={(value) => `${value}%`}
                    ticks={[0, 20, 40, 60, 80, 100]}
                  />
                  <YAxis
                    width={sm ? 50 : 70}
                    type="category"
                    tickFormatter={(value) => tickFormatterMobile(value, sm)}
                    dataKey="type"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#000',
                    }}
                  />
                  <Legend
                    formatter={renderColorfulLegendText}
                    iconType="square"
                    iconSize={15}
                    align="center"
                    wrapperStyle={{
                      bottom: -20,
                    }}
                  />
                  <Bar dataKey="positive" stackId="a" fill="#58CAA1">
                    <LabelList
                      valueAccessor={valueAccessor('positive')}
                      style={{
                        fill: '#fff',
                        fontWeight: '700',
                        fontSize: '0.75rem',
                      }}
                    />
                  </Bar>
                  <Bar dataKey="negative" stackId="a" fill="#F85959">
                    <LabelList
                      valueAccessor={valueAccessor('negative')}
                      style={{
                        fill: '#fff',
                        fontWeight: '700',
                        fontSize: '0.75rem',
                      }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default CardSocialMediaPillar;
