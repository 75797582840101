import { BrandRanking } from '@/lib/models/deiBrandRankings';
import * as types from './types';

export const chooseBrand = (data: BrandRanking) => ({
  type: types.CHOOSE_BRAND,
  payload: { data },
});
export const resetBrand = () => ({
  type: types.RESET_BRAND,
});

export const deiRankingRequest = (data: any) => {
  return {
    type: types.DEI_RANKING_REQUEST,
    payload: { data },
  };
};

export const updateDeiRankingSuccess = (data) => {
  return {
    type: types.DEI_RANKING_SUCCESS,
    payload: { data },
  };
};

export const updateDeiRankingFailure = () => {
  return {
    type: types.DEI_RANKING_FAILURE,
  };
};

const DeiRankingActions = {
  deiRankingRequest,
  updateDeiRankingSuccess,
  updateDeiRankingFailure,
  chooseBrand,
  resetBrand,
};

export default DeiRankingActions;
