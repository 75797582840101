import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import {
  LineChart,
  Line,
  ReferenceLine,
  XAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { BrandMonthProps } from '@/lib/models/charts';

const ChartBrandMonth = ({ data, height }: BrandMonthProps) => {
  const sm = useMediaQuery('(max-width: 529px)');
  const [avarage, setAvarege] = useState<number>(0);
  useEffect(() => {
    const avg = data.reduce((a, { value }) => a + value, 0) / data.length;

    setAvarege(Math.round(avg));
  }, [data]);

  return (
    <ResponsiveContainer width={'100%'} height={height}>
      <LineChart data={data} margin={{ right: 30, left: 30 }}>
        {!sm ? (
          <XAxis
            tickMargin={10}
            type="category"
            dataKey="date"
            stroke="#C4C4C4"
            style={{
              fontWeight: '300',
              fontSize: '0.75rem',
              fill: '#000',
            }}
            interval="preserveStartEnd"
            padding={{ left: 40, right: 40 }}
          />
        ) : null}
        <Tooltip />
        <ReferenceLine
          y={avarage}
          strokeDasharray="2 2"
          stroke="#C4C4C4"
          label={{
            position: 'insideBottomRight',
            value: avarage,
            fontSize: '1rem',
            fill: '#767676',
            fontWeight: '300',
            dy: -5,
          }}
          alwaysShow={true}
          strokeWidth={2}
        />
        <Line dataKey="value" stroke="#5D5FEF" fill="#5D5FEF" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartBrandMonth;
