import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Avatar,
  FormControlLabel,
  Switch,
  Button,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChartBrandMonth from '@/components/ChartBrandMonth';
import Strings from '@/lib/constants/values/strings.json';
import { CardBranModel } from '@/lib/models/alertManagement';
import { useNavigate, generatePath } from 'react-router-dom';

import { RoutesMap } from '@/routers/map';
import { CustomSlider } from './styles';

const CardBrand: React.FC<CardBranModel> = ({ item }: CardBranModel) => {
  const navigate = useNavigate();

  const directHandle = () => {
    const path = generatePath(RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS, {
      id: item.id,
      days: '30',
    });
    navigate(path);
  };

  const [value, setValue] = React.useState<number[]>([0, 100]);
  const [checked, setChecked] = React.useState<boolean>(false);

  const handleChange = (newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - 1), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + 1)]);
    }
  };

  React.useEffect(() => {
    setValue([item.min, item.max]);
    setChecked(item.active);
  }, [item]);

  return (
    <Grid container mt={2.5}>
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <CardContent
            sx={{
              padding: '0px 24px !important',
            }}
          >
            <Grid
              container
              sx={{
                paddingTop: {
                  sm: '0',
                  md: '0',
                  xs: '24px',
                },
                paddingBottom: {
                  sm: '24px',
                  md: '0',
                  xs: '24px',
                },
              }}
            >
              <Grid
                item
                xs={12}
                sm={2}
                md={1}
                borderRight={1}
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                sx={{
                  borderColor: {
                    xs: 'transparent',
                    sm: 'transparent',
                    md: '#E5E5E5',
                  },
                  paddingRight: {
                    xs: '0',
                    sm: '24px',
                    md: '24px',
                  },
                }}
              >
                <Tooltip title={item.name}>
                  <>
                    {item.image ? (
                      <Avatar
                        alt={item.name}
                        src={item.image}
                        variant="square"
                        sx={{
                          width: '107px',
                          height: '107px',
                        }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          textTransform: 'uppercase',
                          width: '107px',
                          height: '107px',
                          fontSize: '50px',
                          fontWeight: '700',
                        }}
                        variant="square"
                      >
                        {item.name.length ? item.name.charAt(0) : ''}
                      </Avatar>
                    )}
                  </>
                </Tooltip>
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                md={5}
                paddingTop="30px"
                sx={{
                  paddingBottom: {
                    sm: '30px',
                    md: '30px',
                    xs: '0',
                  },
                }}
              >
                <ChartBrandMonth data={item.chart} height={150} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                borderLeft={1}
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
                sx={{
                  borderColor: {
                    xs: 'transparent',
                    sm: 'transparent',
                    md: '#E5E5E5',
                  },
                  paddingLeft: {
                    xs: '0',
                    sm: '44px',
                    md: '44px',
                  },
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  columnSpacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    sx={{
                      paddingBottom: {
                        sm: '0',
                        md: '0',
                        xs: '24px',
                      },
                    }}
                  >
                    <FormControlLabel
                      control={<Switch />}
                      label={Strings.active}
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    md={7}
                    justifyContent="center"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                      paddingBottom: {
                        sm: '0',
                        md: '0',
                        xs: '24px',
                      },
                    }}
                  >
                    <CustomSlider
                      color="secondary"
                      value={value}
                      onChange={(_, val, active) => handleChange(val, active)}
                      valueLabelDisplay="auto"
                      disableSwap
                      size="medium"
                      marks={[
                        {
                          value: 0,
                          label: '0',
                        },
                        {
                          value: 100,
                          label: '100',
                        },
                      ]}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    justifyContent="center"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Button
                      variant="outlined"
                      onClick={directHandle}
                      sx={{ height: '48px', width: '130px' }}
                    >
                      <AddIcon sx={{ mr: '10px', mt: '-2px' }} />
                      {Strings.details}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CardBrand;
