import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import BagdeInfo from '@/components/BagdeInfo';
import Logo from '../Logo';
import { TransparenceTableModel } from '@/lib/models/transparenceTable';
import TableHeadPagination from '../TableHeadPagination';
import socialIMG from '@/lib/constants/socialIMG';
import StyledTableRow from '../StyledTableRow';

type TransparenceTableDataProps = {
  data: TransparenceTableModel[];
};

const TransparenceTableData: React.FC<TransparenceTableDataProps> = ({
  data,
}: TransparenceTableDataProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLinkClick = (link?: string) => {
    if (link) {
      window.open(`${link}`, '_blank');
    }
  };

  const linkFormated = (link?: string) => {
    const array = link?.split('/');
    return `${array[0]}//${array[2]}`;
  };

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHeadPagination />
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, key) => (
                <StyledTableRow key={key}>
                  <TableCell component="th" scope="row">
                    <Typography
                      onClick={() => handleLinkClick(row.socialMedia)}
                      sx={{
                        fontSize: '12px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {linkFormated(row?.socialMedia)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    {row.date}
                  </TableCell>
                  <TableCell align="center">
                    {row.channel && (
                      <Logo
                        src={socialIMG[row.channel?.toLocaleLowerCase()]}
                        size={34}
                        alt="Network Social"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.race.white > 0 && <BagdeInfo value={row.race.white} />}
                  </TableCell>
                  <TableCell>
                    {row.race.black > 0 && <BagdeInfo value={row.race.black} />}
                  </TableCell>
                  <TableCell>
                    {row.race.asian > 0 && <BagdeInfo value={row.race.asian} />}
                  </TableCell>
                  <TableCell align="center">
                    {row.sexualOrientation.lgbtqimore > 0 && (
                      <BagdeInfo value={row.sexualOrientation.lgbtqimore} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.gender.male > 0 && (
                      <BagdeInfo value={row.gender.male} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.gender.female > 0 && (
                      <BagdeInfo value={row.gender.female} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.age.children > 0 && (
                      <BagdeInfo value={row.age.children} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.age.adult > 0 && <BagdeInfo value={row.age.adult} />}
                  </TableCell>
                  <TableCell align="center">
                    {row.age.elder > 0 && <BagdeInfo value={row.age.elder} />}
                  </TableCell>
                  <TableCell align="center">
                    {row.comorbidity.physical > 0 && (
                      <BagdeInfo value={row.comorbidity.physical} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.comorbidity.pwd > 0 && (
                      <BagdeInfo value={row.comorbidity.pwd} />
                    )}
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TransparenceTableData;
