import { useMemo } from 'react';
import { BrandRanking } from '@/lib/models/deiBrandRankings';

const useBrandRankingMax = (data: BrandRanking) => {
  const max = useMemo(() => {
    let numberMax = 0;

    data?.diversitiesPilar?.forEach((diversitie) => {
      if (diversitie.score > numberMax) numberMax = diversitie.score;
    });
    return numberMax;
  }, [data?.diversitiesPilar]);
  return max;
};

export default useBrandRankingMax;
