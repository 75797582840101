import { RootState } from '@/store';

const getDeiRankingData = (state: RootState) => state.deiRanking.data;

const getLoading = (state: RootState) => state.deiRanking.loading;

const getBrand = (state: RootState) => state.deiRanking.brandCurrent;

const DeiRankingSelectors = {
  getLoading,
  getDeiRankingData,
  getBrand,
};

export default DeiRankingSelectors;
