import {
  AlertManagementItemModel,
  AlertManagementNotificationModel,
} from '@/lib/models/alertManagement';

import {
  ALERT_MANAGEMENT_FAILURE,
  ALERT_MANAGEMENT_REQUEST,
  ALERT_MANAGEMENT_SUCCESS,
  ALERT_MANAGEMENT_NOTIFICATIONS_REQUEST,
  ALERT_MANAGEMENT_NOTIFICATIONS_SUCCESS,
} from './types';

type AlertManagementState = {
  data: AlertManagementItemModel[];
  loading: false;
  brand: AlertManagementNotificationModel;
};

const INITIAL_STATE: AlertManagementState = {
  data: [],
  loading: false,
  brand: null,
};

export default function alertManagementReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ALERT_MANAGEMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALERT_MANAGEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case ALERT_MANAGEMENT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ALERT_MANAGEMENT_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALERT_MANAGEMENT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        brand: action.payload.data,
      };
    default:
      return state;
  }
}
