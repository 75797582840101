import { combineReducers } from 'redux';

import authReducer from '../ducks/auth';
import filtersReducer from '../ducks/filters';
import myReportsReducers from '../ducks/myReports/reducers';
import transparenceTableReducers from '../ducks/transparenceTable';
import uploadReducer from '../ducks/upload';
import alertManagementReducer from '../ducks/alertManagement';
import deiRankingReducers from '../ducks/deiRanking/reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  filters: filtersReducer,
  transparenceData: transparenceTableReducers,
  upload: uploadReducer,
  alertManagement: alertManagementReducer,
  myReports: myReportsReducers,
  deiRanking: deiRankingReducers,
});

export default rootReducer;
