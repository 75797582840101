import { put, takeLatest, call } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as actions from './actions';
import * as deiRanking from '@/services/deiRanking';
import * as types from './types';
import Strings from '@/lib/constants/values/strings.json';

export function* deiRankingRequest(payload: any) {
  const { data } = payload.payload;

  try {
    const response = yield call(deiRanking.deiRankingRequest, data);

    yield put(actions.updateDeiRankingSuccess(response.data));
  } catch (error) {
    toast.error(Strings.errorApi);
    yield put(actions.updateDeiRankingFailure());
  }
}

export default function* watchDeiRanking() {
  yield takeLatest(types.DEI_RANKING_REQUEST, deiRankingRequest);
}
