import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { authSelectors } from '@/store/selectors';
import { useLocation } from 'react-router-dom';
import { RoutesMap } from '@/routers/map';
import history from '@/lib/utils/history';

const DefaultUpload = ({ children }) => {
  const location = useLocation();
  const isTemplateLoaded = useSelector(authSelectors.getTemplateIsLoaded);

  useEffect(() => {
    if (location.pathname === RoutesMap.UPLOAD && isTemplateLoaded) {
      history.replace(RoutesMap.BASE);
      return;
    }
  }, [isTemplateLoaded, location]);
  return <>{!isTemplateLoaded && children}</>;
};

export default DefaultUpload;
