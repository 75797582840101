import React, { useRef, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ReactSpeedometer from 'react-d3-speedometer';
import Strings from '@/lib/constants/values/strings.json';

type CardOverallProps = {
  value: number;
};

const Wrapper = styled(Grid)`
  .speedometer {
    margin-left: 2px;
  }
  .MuiGrid-item div {
    text-align: center;
  }
`;

const CardOverall = ({ value }: CardOverallProps) => {
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const ref = useRef(null);
  const [width, setWidth] = useState<number>(0);

  useLayoutEffect(() => {
    if (medium && !small) {
      setWidth(400);
    } else {
      if (ref.current.offsetWidth > 400) {
        setWidth(400);
      } else if (ref.current.offsetWidth > 800) {
        setWidth(500);
      } else {
        setWidth(ref.current.offsetWidth);
      }
    }
  }, [medium, small, ref.current?.offsetWidth]);

  return (
    <Card>
      <CardContent
        sx={{
          padding: '24px 27px',
          minHeight: '369px',
        }}
      >
        <Typography variant="subtitle1">
          {Strings.charts.titleOverall}
        </Typography>

        <Wrapper
          container
          alignItems="center"
          justifyContent="center"
          ref={ref}
          height={261}
        >
          <Grid item xs={10} sm={10} md={10} lg={10} mt={2}>
            <ReactSpeedometer
              height={width - width / 2}
              width={width - width / 5}
              needleHeightRatio={0.6}
              maxSegmentLabels={2}
              segments={1000}
              value={value}
              minValue={0}
              maxValue={200}
              needleColor="#EB5757"
              ringWidth={30}
              startColor="#F85959"
              endColor="#59CAA2"
              textColor="#000"
              valueTextFontSize="28px"
              labelFontSize={'20px'}
              paddingVertical={10}
              forceRender
              paddingHorizontal={5}
            />
          </Grid>
        </Wrapper>
      </CardContent>
    </Card>
  );
};

export default CardOverall;
