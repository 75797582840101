import { RootState } from '@/store';

const getAlertManagementData = (state: RootState) => state.alertManagement.data;
const getAlertBrand = (state: RootState) => state.alertManagement.brand;
const getLoading = (state: RootState) => state.alertManagement.loading;

const AlertManagementSelectors = {
  getLoading,
  getAlertManagementData,
  getAlertBrand,
};

export default AlertManagementSelectors;
