import { TableRow } from '@mui/material';

import { styled } from '@mui/material/styles';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.secondary.light,
  },
  'td, th': {
    borderLeft: '1px solid #E5E5E5',
  },
  'td:first-child, th:first-child': {
    borderLeft: 0,
  },
}));

export default StyledTableRow;
