export const colorHeatmap = (value: number) => {
  if (value < 50) {
    return '#F85959';
  } else if (value < 80) {
    return '#FFA318';
  }
  return '#58CAA1';
};

export const tickFormatterMobile = (value: string, mobile: boolean) => {
  if (!mobile) {
    return value;
  }
  const limit = 4; //
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};

export const colorPlatform = (value: string) => {
  switch (value) {
    case 'Instagram':
      return '#E47474';
    case 'TikTok':
      return '#58CAA1';
    case 'Facebook':
      return '#5D97EF';
    case 'Twitter':
      return '#5DC3EF';
    case 'Youtube':
      return '#E9463B';
    default:
      return '#5D5FEF';
  }
};

export const colorsEvolution = {
  0: { primary: '#E47474', secondary: '#E47474A1' },
  1: { primary: '#58CAA1', secondary: '#58CAA1A1' },
  2: { primary: '#5D97EF', secondary: '#5D97EFA1' },
  3: { primary: '#E9463B', secondary: '#E9463BA1' },
  4: { primary: '#5D5FEF', secondary: '#5D5FEFA1' },
  5: { primary: '#E42474', secondary: '#E47474A1' },
  6: { primary: '#58CDAA1', secondary: '#58CAA1A1' },
  7: { primary: '#5D27EF', secondary: '#5D97EFA1' },
  8: { primary: '#E9263B', secondary: '#E9463BA1' },
  9: { primary: '#2F91FF', secondary: '#5D5FEFA1' },
};

export const normalise = (value: number, max: number) =>
  ((value - 0) * 100) / (max - 0);
