import { ElementType } from 'react';
import { RouteProps } from 'react-router-dom';

import Home from '@/pages/Home';
import SignIn from '@/pages/SignIn';
import { RoutesMap } from './map';
import TransparenceTable from '@/pages/TransparenceTable';
import MyReports from '@/pages/MyReports';
import AlertManagement from '@/pages/AlertManagement';
import AlertManagementNotifications from '@/pages/AlertManagementNotifications';
import DeiRanking from '@/pages/DeiRanking';
import Upload from '@/pages/Upload';

type Route =
  | {
      component: ElementType;
      isPrivate: boolean;
      key: string;
    } & RouteProps;

const routes: Route[] = [
  {
    component: Home,
    path: RoutesMap.BASE,
    key: RoutesMap.BASE,
    isPrivate: true,
  },
  {
    component: TransparenceTable,
    path: RoutesMap.TRANSPARENCE_TABLE,
    key: RoutesMap.TRANSPARENCE_TABLE,
    isPrivate: true,
  },
  {
    component: MyReports,
    path: RoutesMap.MY_REPORTS,
    key: RoutesMap.MY_REPORTS,
    isPrivate: true,
  },
  {
    component: AlertManagement,
    path: RoutesMap.ALERT_MANAGEMENT,
    key: RoutesMap.ALERT_MANAGEMENT,
    isPrivate: true,
  },
  {
    component: AlertManagementNotifications,
    path: RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS,
    key: RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS,
    isPrivate: true,
  },
  {
    component: DeiRanking,
    path: RoutesMap.DEI_Ranking,
    key: RoutesMap.DEI_Ranking,
    isPrivate: true,
  },
  {
    component: Upload,
    path: RoutesMap.UPLOAD,
    key: RoutesMap.UPLOAD,
    isPrivate: true,
  },
  {
    component: SignIn,
    path: RoutesMap.LOGIN,
    key: RoutesMap.LOGIN,
    isPrivate: false,
  },
];

export default routes;
