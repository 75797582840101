import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CardBrand from '@/components/CardBrand';
import Strings from '@/lib/constants/values/strings.json';
import { AlertManagementItemModel } from '@/lib/models/alertManagement';
import { filtersActions, alertManagementActions } from '@/store/actions';
// import { RoutesMap } from '@/routers/map';
import { alertManagementSelectors } from '@/store/selectors';
import AlertManagementLoading from '@/components/loadings/AlertManagementLoading';

const AlertManagement: React.FC = () => {
  const data = useSelector(alertManagementSelectors.getAlertManagementData);
  const loading = useSelector(alertManagementSelectors.getLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(filtersActions.resetMetrics());
    dispatch(alertManagementActions.alertManagementRequest());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(alertManagementActions.alertManagementRequest());
    };

    const interval = setInterval(() => {
      fetchData();
    }, 7200000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        padding: '30px 30px 30px 30px',
      }}
    >
      <Typography variant="h5">{Strings.myBrands}</Typography>
      {loading ? (
        <AlertManagementLoading />
      ) : (
        <Box component="div">
          {data.map((value: AlertManagementItemModel) => (
            <CardBrand item={value} key={value.id} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AlertManagement;
