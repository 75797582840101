import api from './api';

export const myReportsRequest = (data: { brand_id?: string; days?: string }) =>
  api.get('post/dashboard/getAll', {
    params: data,
  });
export const myReportsOverallRequest = () => api.get('post/dashboard/overall');
export const myReportsPlatformRequest = () =>
  api.get('post/dashboard/platform-comparison');
export const myReportsBreakdownScoreRequest = () =>
  api.get('post/dashboard/breakdown');
export const myReportsHeatMapRequest = () => api.get('post/dashboard/heatmap');
export const myReportsCompetitorEvolutionRequest = () =>
  api.get('post/dashboard/brand-competitor');
export const myReportsSocialMediaFeelingRequest = () =>
  api.get('post/dashboard/media-feeling');
export const myReportsSocialMediaOverallRequest = () =>
  api.get('post/dashboard/overall-media-feeling');
