import styled from 'styled-components';
import { Slider } from '@mui/material';

export const CustomSlider = styled(Slider)`
  margin-bottom: 0;
  margin-top: 10px;
  width: 80%;
  .MuiSlider-rail {
    background-color: #e0e0e0;
    height: 8px;
  }
  .MuiSlider-track {
    background-image: linear-gradient(0.25turn, #eb5757, #6fcf97);
    border: 0;
    height: 8px;
  }
  .MuiSlider-thumb[data-index='0'] {
    color: #eb5757;
  }
  .MuiSlider-thumb[data-index='1'] {
    color: #6fcf97;
  }
  .MuiSlider-mark {
    background-color: transparent;
  }
  .MuiSlider-markLabel {
    color: #5d5fef;
    font-size: 1rem;
  }
  .MuiSlider-markLabel[data-index='0'] {
    top: -25px;
    transform: translateX(0%);
  }
  .MuiSlider-markLabel[data-index='1'] {
    top: -25px;
    transform: translateX(-100%);
  }
`;
