import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  ResponsiveContainer,
  YAxis,
} from 'recharts';
import { BreakdownScoreProps } from '@/lib/models/charts';
import { tickFormatterMobile } from '@/lib/utils/functions';
import Strings from '@/lib/constants/values/strings.json';

const CustomizedLabel = (props: any) => {
  const { x, y, value } = props;
  const fireOffset = value.toString().length < 3;
  const offset = fireOffset ? 2 : -15;
  const offsetX = fireOffset ? 18 : 22;
  return (
    <text
      fontSize="0.75rem"
      x={x + offsetX}
      y={y - offset}
      fill="#000"
      textAnchor="end"
      fontWeight="400"
    >
      {value}
    </text>
  );
};

const CardBreakdownScore = ({ data }: BreakdownScoreProps) => {
  const xd = useMediaQuery('(max-width: 529px)');

  return (
    <Card>
      <CardContent
        sx={{
          padding: '24px 27px',
          height: '369px',
        }}
      >
        <Typography variant="subtitle1">
          {Strings.charts.titleBreakdownScore}
        </Typography>
        {data && (
          <Grid container alignItems="center" justifyContent="center" mt="20px">
            <Grid item xs={12} sm={12} md={12}>
              <ResponsiveContainer width={'99%'} height={261}>
                <BarChart
                  data={data}
                  margin={{
                    top: 10,
                    right: 0,
                    left: 0,
                    bottom: 10,
                  }}
                >
                  <XAxis
                    width={150}
                    tickFormatter={(value) => tickFormatterMobile(value, xd)}
                    type="category"
                    dataKey="type"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#000',
                    }}
                    dy={10}
                    minTickGap={-10}
                  />
                  <YAxis
                    width={30}
                    type="number"
                    dataKey="value"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#767676',
                    }}
                    ticks={[0, 50, 100, 150, 200]}
                    dy={-5}
                  />
                  <CartesianGrid vertical={false} stroke="#E5E5E5" />
                  <Bar
                    dataKey="value"
                    barSize={23}
                    fill="#5D5FEF"
                    background={{ fill: '#E5E5E5' }}
                    label={<CustomizedLabel />}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default CardBreakdownScore;
