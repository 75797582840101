import { SET_LOADING, SET_PROGRESS, RESET_PROGRESS } from './types';

export type ProgressType = {
  percentage: number;
  total: number;
  loaded: number;
};

const progressDefault: ProgressType = {
  percentage: 0,
  total: 0,
  loaded: 0,
};

export type UploadType = {
  loading: boolean;
  progress: ProgressType;
};

const initialState: UploadType = {
  loading: false,
  progress: progressDefault,
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case SET_PROGRESS:
      return {
        ...state,
        progress: action.data,
      };
    case RESET_PROGRESS:
      return {
        ...state,
        progress: progressDefault,
      };
    default:
      return state;
  }
};

export default uploadReducer;
