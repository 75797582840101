import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import Header from '@/components/Header';
import EnterpriseHeader from '@/components/EnterpriseHeader';
import EnterpriseNameHeader from '@/components/EnterpriseNameHeader';
import useRoutesNameHeader from './hooks/useRoutesNameHeader';
import { useSelector } from 'react-redux';
import { authSelectors } from '@/store/selectors';
import { RoutesMap } from '@/routers/map';
import history from '@/lib/utils/history';

const DefaultLayout = ({ children }) => {
  const token = useSelector(authSelectors.getToken);

  const namesHeader = useRoutesNameHeader();
  const isTemplateLoaded = useSelector(authSelectors.getTemplateIsLoaded);

  useEffect(() => {
    if (token && !isTemplateLoaded) {
      history.replace(RoutesMap.UPLOAD);
      return;
    }
  }, [isTemplateLoaded, token]);
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}
    >
      {isTemplateLoaded && (
        <>
          <Header />
          {namesHeader.isVisible && (
            <EnterpriseNameHeader title={namesHeader.title} />
          )}
        </>
      )}
      {isTemplateLoaded && <EnterpriseHeader />}
      <Box
        component="main"
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DefaultLayout;
