import * as types from './types';
import { User } from './reducers';

export const authRequest = (data: { password: string; username: string }) => ({
  type: types.AUTH_REQUEST,
  data,
});

export const userLogo = (data: string) => ({
  type: types.USER_LOGO,
  data,
});

export const setTemplateIsLoaded = (data: boolean) => ({
  type: types.SET_TEMPLATE_IS_LOADED,
  data,
});

export const setToken = (data: string) => ({
  type: types.SET_TOKEN,
  data,
});

export const setUser = (data: User) => ({
  type: types.SET_USER,
  data,
});

export const setLoading = () => ({
  type: types.SET_LOADING,
});

export const resetUser = () => ({
  type: types.RESET_USER,
});

export const logout = () => ({
  type: types.LOGOUT,
});

const AuthActions = {
  authRequest,
  setToken,
  setUser,
  setTemplateIsLoaded,
  setLoading,
  resetUser,
  logout,
};

export default AuthActions;
