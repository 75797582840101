import {
  Facebook,
  Instagran,
  Linkedin,
  Twitter,
  Youtube,
  Tiktok,
} from './imgs';

const socialIMG = {
  twitter: Twitter,
  facebook: Facebook,
  instagram: Instagran,
  youtube: Youtube,
  linkedin: Linkedin,
  tiktok: Tiktok,
};
export default socialIMG;
