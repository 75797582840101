import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { ComparativeAlertTableModel } from '@/lib/models/alertManagement';
import Strings from '@/lib/constants/values/strings.json';

type ComparativeAlertTableDataProps = {
  data: ComparativeAlertTableModel[];
};

const renderTextHeader = (str: string) => {
  return (
    <Typography
      variant="h6"
      component="p"
      color="secondary"
      align="center"
      sx={{
        fontSize: 16,
      }}
    >
      {str}
    </Typography>
  );
};

const renderIcon = (value: string) => {
  switch (value) {
    case 'up':
      return <ArrowUpwardIcon color="success" />;
    case 'down':
      return <ArrowDownwardIcon color="error" />;
    default:
      return <DragHandleIcon color="secondary" />;
  }
};

const ComparativeAlertTableData: React.FC<ComparativeAlertTableDataProps> = ({
  data,
}: ComparativeAlertTableDataProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: 'none' }} />
            <TableCell
              align="center"
              colSpan={5}
              sx={{ border: 'none', borderLeft: '1px solid #E5E5E5' }}
            >
              {renderTextHeader(Strings.comparativeAlertTable.channel)}
            </TableCell>
            <TableCell
              sx={{ border: 'none', borderLeft: '1px solid #E5E5E5' }}
            />
          </TableRow>
          <TableRow color="primary.contrastText" sx={{ height: '55px' }}>
            <TableCell>
              {renderTextHeader(Strings.comparativeAlertTable.diversity)}
            </TableCell>
            <TableCell align="center" sx={{ borderLeft: '1px solid #E5E5E5' }}>
              Facebook
            </TableCell>
            <TableCell align="center">Twitter</TableCell>
            <TableCell align="center">Instagram</TableCell>
            <TableCell align="center">Tiktok</TableCell>
            <TableCell align="center">Youtube</TableCell>
            <TableCell sx={{ borderLeft: '1px solid #E5E5E5' }}>
              {renderTextHeader(Strings.comparativeAlertTable.observation)}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, key) => (
            <TableRow
              key={key}
              sx={{
                'td, th': {
                  borderLeft: '1px solid #E5E5E5',
                },
                'td:first-child, th:first-child': {
                  borderLeft: 0,
                },
                '&:nth-of-type(odd)': {
                  backgroundColor: '#FAFAFA',
                },
              }}
            >
              <TableCell
                sx={{
                  fontSize: '16px',
                  fontWeight: '300',
                }}
              >
                {row.diversity}
              </TableCell>
              <TableCell
                sx={{ borderLeft: '1px solid #E5E5E5' }}
                align="center"
                width="8%"
              >
                {renderIcon(row.channel.facebook)}
              </TableCell>
              <TableCell
                sx={{ borderLeft: '1px solid #E5E5E5' }}
                align="center"
                width="8%"
              >
                {renderIcon(row.channel.twitter)}
              </TableCell>
              <TableCell
                sx={{ borderLeft: '1px solid #E5E5E5' }}
                align="center"
                width="8%"
              >
                {renderIcon(row.channel.instagram)}
              </TableCell>
              <TableCell
                sx={{ borderLeft: '1px solid #E5E5E5' }}
                align="center"
                width="8%"
              >
                {renderIcon(row.channel.tiktok)}
              </TableCell>
              <TableCell
                sx={{ borderLeft: '1px solid #E5E5E5' }}
                align="center"
                width="8%"
              >
                {renderIcon(row.channel.youtube)}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: '16px',
                  fontWeight: '300',
                }}
                width="50%"
                dangerouslySetInnerHTML={{ __html: row.channel?.observation }}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComparativeAlertTableData;
