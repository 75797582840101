import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
  TableContainer,
  Table,
  Paper,
  TableBody,
} from '@mui/material';

import translate from '@/lib/constants/values/strings.json';

const DeiBrandRankingsLoading: React.FC = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
            >
              {translate.brandRanking.BrandRankingTitle}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 10 }, () =>
            Math.floor(Math.random() * 10)
          )?.map((value) => (
            <TableRow key={value} hover>
              <TableCell scope="row">
                <Skeleton
                  sx={{
                    transform: 'unset',
                  }}
                  width="100%"
                  height={25}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeiBrandRankingsLoading;
