import React from 'react';
import { Box, Typography } from '@mui/material';

const shapeStyles = {
  bgcolor: 'primary.main',
  width: 36,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
};
const shapeCircleStyles = { borderRadius: '50%' };

type BageInfoProps = {
  value: number;
};

const BagdeInfo = ({ value }: BageInfoProps) => {
  return (
    <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }}>
      <Typography
        color="primary.contrastText"
        component="p"
        sx={{ fontWeight: 700 }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default BagdeInfo;
