import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#7476ed',
      main: '#5D5FEF',
      dark: '#4b4dd1',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#FAFAFA',
      main: '#767676',
      dark: '#4d4a56',
      contrastText: '#ffffff',
    },
    error: {
      light: '#f05357',
      main: '#F85959',
      dark: '#a51c20',
      contrastText: '#fff',
    },
    success: {
      light: '#f05357',
      main: '#58CAA1',
      dark: '#a51c20',
      contrastText: '#fff',
    },
    text: {
      primary: '#000000',
      secondary: '#1A1A24',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontWeight: '400',
          lineHeight: '1.5rem',
          textTransform: 'uppercase',
          padding: '16px 32px',
          fontSize: '0.875rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'EYInterstate',
          fontWeightMedium: 400,
          fontStyle: 'normal',
        },

        h3: {
          fontSize: '1.875rem',
          fontWeight: '300',
        },
        h4: {
          fontSize: '1.625rem',
          fontWeight: '700',
        },
        h5: {
          fontSize: '1.5rem',
          fontWeight: '300',
        },
        h6: {
          fontSize: '1.25rem',
          fontWeight: '300',
        },
        subtitle1: {
          fontSize: '0.875rem',
          fontWeight: '300',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#000000',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(26, 26, 36, 0.78)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'rgba(26, 26, 36, 0.98)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(26, 26, 36, 0.78)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#BDBDBD',
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 0.12,
            backgroundColor: '#000',
          },
          '&.Mui-disabled': {
            color: '#BDBDBD',
          },
        },

        track: {
          opacity: 0.12,
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  typography: {
    fontFamily: 'EYInterstate',
    fontWeightLight: 'lighter',
    fontWeightMedium: 'normal',
    fontWeightRegular: 'normal',
    fontWeightBold: 'bold',
  },
  shadows: [
    'none',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
    '0 4px 24px 0 rgba(34,41,47,.1)',
  ],
});

export default theme;
