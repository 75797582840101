import { useMemo } from 'react';
import { BrandRanking } from '@/lib/models/deiBrandRankings';

const useBrandRankingsMax = (data: BrandRanking[]) => {
  const max = useMemo(() => {
    let numberMax = 0;

    data?.forEach((ranking) => {
      if (ranking.score > numberMax) numberMax = ranking.score;
    });
    return numberMax;
  }, [data]);
  return max;
};

export default useBrandRankingsMax;
