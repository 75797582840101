import { TransparenceTableModel } from '@/lib/models/transparenceTable';

import {
  TRANSPARENCE_TABLE_FAILURE,
  TRANSPARENCE_TABLE_REQUEST,
  TRANSPARENCE_TABLE_SUCCESS,
} from './types';

type TransparenceDataState = {
  data: TransparenceTableModel[];
  loading: false;
};

const INITIAL_STATE: TransparenceDataState = {
  data: [],
  loading: false,
};

export default function transparenceTableReducers(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case TRANSPARENCE_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TRANSPARENCE_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case TRANSPARENCE_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
