import React from 'react';
import { Skeleton } from '@mui/material';

export default function MyReportsLoading() {
  return (
    <Skeleton
      sx={{
        minHeight: '369px',
        transform: 'unset',
      }}
      width="100%"
    />
  );
}
