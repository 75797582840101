import { put, takeLatest, call } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as actions from './actions';
import * as filters from '@/services/filters';
import * as types from './types';
import Strings from '@/lib/constants/values/strings.json';
import { FilterData, FilterSelector, FilterType } from './reducers';
import { RoutesMap } from '@/routers/map';

type FiltersEnable = {
  date: boolean;
  brands: boolean;
};

function enable(routes) {
  switch (routes) {
    case RoutesMap.MY_REPORTS:
      return {
        date: true,
        brands: true,
      };
    case RoutesMap.ALERT_MANAGEMENT:
      return {
        date: true,
        brands: false,
      };
    case RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS:
      return {
        date: true,
        brands: false,
      };
    case RoutesMap.DEI_Ranking:
      return {
        date: true,
        brands: false,
      };
    default:
      return { date: false, brands: false };
  }
}

export function* filtersRequest(payload: any) {
  const { data } = payload;
  try {
    const enableFilters: FiltersEnable = enable(data);
    const views: FilterData[] = [];
    if (enableFilters.brands) {
      const responseBrand = yield call(filters.getBrandFilter);
      const viewBrand: FilterData = {
        title: 'Brands',
        type: FilterType.BRANDS,
        options: responseBrand.data.map((brand) => ({
          name: brand.brand_name,
          value: brand.brand_id,
        })),
      };
      views.push(viewBrand);
    }
    if (enableFilters.date) {
      const responseTimeline = yield call(filters.getPeriodicityFilterValues);
      const viewTimeLine: FilterData = {
        title: 'Datetime',
        type: FilterType.TIMELINE,
        options: responseTimeline.data.map((periodict) => ({
          name: periodict.description,
          value: periodict.value,
        })),
      };

      views.push(viewTimeLine);

      const periodicity = responseTimeline.data.find(
        (periodict) => periodict.default
      );
      const timelineSelected: FilterSelector = {
        choose: periodicity.value,
        type: FilterType.TIMELINE,
      };
      yield put(actions.updateSelectorFilters(timelineSelected));
    }

    yield put(
      actions.filterSuccess({
        type: data,
        views,
      })
    );
  } catch (error) {
    yield put(actions.filterFailure());
    toast.error(Strings.errorApi);
  }
}

export default function* watchFilter() {
  yield takeLatest(types.FILTER_REQUEST, filtersRequest);
}
