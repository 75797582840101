import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import geoUrl from '@/assets/files/south-america.json';
import { HeatMapProps } from '@/lib/models/charts';
import { colorHeatmap } from '@/lib/utils/functions';

const Heatmap = ({ data }: HeatMapProps) => {
  const checkColor = (geo: any) => {
    const find = data.find((s) => s.country === geo.properties.geounit);
    if (find) {
      const color = colorHeatmap(find.value);
      return color;
    }
    return '#C4C4C4';
  };

  return (
    <ComposableMap
      height={210}
      width={140}
      projectionConfig={{
        rotate: [58, 20, 0],
        scale: 140,
      }}
    >
      {data.length > 0 && (
        <Geographies geography={geoUrl} projection="geoAzimuthalEqualArea">
          {({ geographies }) =>
            geographies.map((geo: any) => {
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={checkColor(geo)}
                />
              );
            })
          }
        </Geographies>
      )}
    </ComposableMap>
  );
};

export default Heatmap;
