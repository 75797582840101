import React, { useMemo } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Chip, SxProps, Theme } from '@mui/material';

type IntervalChipProps = {
  step: number;
  sx?: SxProps<Theme>;
};

const IntervalChip: React.FC<IntervalChipProps> = (props) => {
  const growingStep = useMemo(() => props.step > 0, [props]);

  if (props.step === 0) return null;
  return (
    <Chip
      icon={
        growingStep ? (
          <ArrowUpwardIcon fontSize="small" sx={{ color: '#fff !important' }} />
        ) : (
          <ArrowDownwardIcon
            fontSize="small"
            sx={{ color: '#fff !important' }}
          />
        )
      }
      label={growingStep ? `+${props.step}` : props.step}
      color={growingStep ? 'success' : 'error'}
      sx={{
        height: 21,
        fontWeight: 700,
        fontSize: 12,
        color: 'primary.contrastText',
        bgcolor: '',
        ...props.sx,
      }}
    />
  );
};

export default IntervalChip;
