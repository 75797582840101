import { RootState } from '@/store';

const getFilters = (state: RootState) => state.filters.views;
const getSelectedFilters = (state: RootState) => state.filters.selectors;
const getNumbersFilters = (state: RootState) => state.filters.numberLoading;
const getLoading = (state: RootState) => state.filters.loading;

const FiltersSelectors = {
  getFilters,
  getSelectedFilters,
  getLoading,
  getNumbersFilters,
};

export default FiltersSelectors;
