export const UPLOAD_TEMPLATE: string = 'upload/UPLOAD_TEMPLATE';
export const SET_LOADING: string = 'upload/SET_LOADING';
export const SET_PROGRESS: string = 'upload/SET_PROGRESS';
export const RESET_PROGRESS: string = 'upload/RESET_PROGRESS';

const UploadTypes = {
  UPLOAD_TEMPLATE,
  SET_LOADING,
  SET_PROGRESS,
  RESET_PROGRESS,
};

export default UploadTypes;
