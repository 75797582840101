export const FILTER_REQUEST: string = 'filters/FILTER_REQUEST';
export const FILTER_SUCCESS: string = 'filters/FILTER_SUCCESS';
export const FILTER_FAILURE: string = 'filters/FILTER_FAILURE';

export const SET_METRICS_TYPE: string = 'filters/SET_METRICS_TYPE';
export const RESET_METRICS: string = 'filters/RESET_METRICS';
export const UPDATE_SELECTED_FILTER: string = 'filters/UPDATE_SELECTED_FILTER';

const FiltersTypes = {
  FILTER_REQUEST,
  FILTER_SUCCESS,
  FILTER_FAILURE,
  SET_METRICS_TYPE,
  RESET_METRICS,
  UPDATE_SELECTED_FILTER,
};

export default FiltersTypes;
