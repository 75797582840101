import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import { useParams, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CardOverall from '@/components/CardOverall';
import ComparativeAlertTableData from '@/components/ComparativeAlertTableData';
import ChartBrandMonth from '@/components/ChartBrandMonth';
import Strings from '@/lib/constants/values/strings.json';
import { filtersActions, alertManagementActions } from '@/store/actions';
import { RoutesMap } from '@/routers/map';
import { alertManagementSelectors, filtersSelectors } from '@/store/selectors';
import AlertManagementNotificationsLoading from '@/components/loadings/AlertManagementNotificationsLoading';
import { FilterType } from '@/store/ducks/filters/reducers';
import history from '@/lib/utils/history';

type RouteParams = {
  id: string;
  days: string;
};

const AlertManagementNotifications: React.FC = () => {
  const sm = useMediaQuery('(max-width: 529px)');
  const { id, days } = useParams<RouteParams>();
  const data = useSelector(alertManagementSelectors.getAlertBrand);
  const loading = useSelector(alertManagementSelectors.getLoading);
  const selectedFilters = useSelector(filtersSelectors.getSelectedFilters);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      filtersActions.filterRequest(RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS)
    );
    if (id) {
      dispatch(
        alertManagementActions.alertManagementNotificationsRequest(id, days)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      if (selectedFilters.length > 0) {
        let daysAux = '';

        const timelineOption = selectedFilters.find(
          (selected) => selected.type === FilterType.TIMELINE
        );
        if (timelineOption) {
          daysAux = timelineOption.choose;
        }

        dispatch(
          alertManagementActions.alertManagementNotificationsRequest(
            id,
            daysAux
          )
        );

        history.replace(
          generatePath(RoutesMap.ALERT_MANAGEMENT_NOTIFICATIONS, {
            id: id,
            days: daysAux,
          })
        );
      }
    }
  }, [dispatch, id, selectedFilters]);

  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        padding: '30px 30px 30px 30px',
      }}
    >
      <Typography variant="h5">{Strings.notifications}</Typography>
      {loading || data === null ? (
        <AlertManagementNotificationsLoading />
      ) : (
        <Box component="div">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4} mt={2.5}>
              <CardOverall value={data.value} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} mt={2.5}>
              <Card>
                <CardContent
                  sx={{
                    paddingTop: {
                      xs: '60px',
                      sm: '24px',
                      md: '24px',
                    },
                    paddingBottom: {
                      xs: '0px',
                      sm: '24px',
                      md: '24px',
                    },
                    paddingX: '24px',
                    minHeight: '369px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <ChartBrandMonth data={data.chart} height={sm ? 120 : 270} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container mt={2.5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ComparativeAlertTableData data={data.comparative} />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default AlertManagementNotifications;
