import {
  TRANSPARENCE_TABLE_FAILURE,
  TRANSPARENCE_TABLE_REQUEST,
  TRANSPARENCE_TABLE_SUCCESS,
} from './types';

export const transparenceTableRequest = () => {
  return {
    type: TRANSPARENCE_TABLE_REQUEST,
  };
};

export const updateTransparenceTableSuccess = (data) => {
  return {
    type: TRANSPARENCE_TABLE_SUCCESS,
    payload: { data },
  };
};

export const updateTransparenceTableFailure = () => {
  return {
    type: TRANSPARENCE_TABLE_FAILURE,
  };
};

const TransparenceTableActions = {
  transparenceTableRequest,
  updateTransparenceTableSuccess,
  updateTransparenceTableFailure,
};

export default TransparenceTableActions;
