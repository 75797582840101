import React from 'react';

import { Card, CardContent, Typography } from '@mui/material';

import Strings from '@/lib/constants/values/strings.json';

const CardBlank = () => {
  return (
    <Card>
      <CardContent
        sx={{
          padding: '24px 27px',
          minHeight: '369px',
        }}
      >
        <Typography variant="subtitle1">
          {Strings.charts.titleOverall}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardBlank;
