import { RootState } from '@/store';

const getTransparenceTableData = (state: RootState) =>
  state.transparenceData.data;

const getLoading = (state: RootState) => state.transparenceData.loading;

const TransparenceTableSelectors = {
  getLoading,
  getTransparenceTableData,
};

export default TransparenceTableSelectors;
