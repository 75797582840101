import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import {
  Legend,
  XAxis,
  ResponsiveContainer,
  YAxis,
  Area,
  ComposedChart,
  //Line,
} from 'recharts';
import { SocialMediaOverallProps } from '@/lib/models/charts';
import Strings from '@/lib/constants/values/strings.json';

const CardSocialMediaOverall = ({ data }: SocialMediaOverallProps) => {
  const renderColorfulLegendText = (value: string) => {
    let str = value;
    if (value === 'social') {
      str = Strings.socialMediaEngagement;
    }
    return (
      <span
        style={{
          color: '#000',
          textTransform: 'capitalize',
          marginRight: '15px',
          fontWeight: '300',
          fontSize: '0.75rem',
        }}
      >
        {str}
      </span>
    );
  };

  return (
    <Card>
      <CardContent
        sx={{
          padding: '24px 27px',
          height: '488px',
        }}
      >
        <Typography variant="subtitle1">
          {Strings.charts.titleSocialMediaOverall}
        </Typography>
        {data && (
          <Grid container alignItems="center" justifyContent="center" mt="30px">
            <Grid item xs={12} sm={12} md={12}>
              <ResponsiveContainer width={'100%'} height={350}>
                <ComposedChart data={data}>
                  <defs>
                    <linearGradient
                      id="colorNegative"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#F85959" stopOpacity={1} />
                      <stop offset="100%" stopColor="#FFFFFF" stopOpacity={1} />
                    </linearGradient>
                    <linearGradient
                      id="colorPositive"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#7FEEC6" stopOpacity={1} />
                      <stop offset="100%" stopColor="#FFFFFF" stopOpacity={1} />
                    </linearGradient>
                  </defs>

                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#767676',
                    }}
                    dy={5}
                  />
                  <YAxis
                    yAxisId="left"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#767676',
                    }}
                    tickMargin={10}
                    tickFormatter={(value) => `${value}%`}
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#767676',
                    }}
                    tickMargin={10}
                  />
                  <Area
                    yAxisId="left"
                    dataKey="positive"
                    stackId="1"
                    fillOpacity={1}
                    strokeWidth={0}
                    fill="url(#colorPositive)"
                    stroke="#58CAA1"
                  />
                  <Area
                    yAxisId="left"
                    strokeWidth={0}
                    stackId="1"
                    dataKey="negative"
                    fillOpacity={1}
                    fill="url(#colorNegative)"
                    stroke="#F85959"
                  />
                  <Legend
                    formatter={renderColorfulLegendText}
                    iconType="square"
                    iconSize={15}
                    align="center"
                    wrapperStyle={{
                      bottom: -20,
                    }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default CardSocialMediaOverall;
