import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import TransparenceTableData from '@/components/TransparenceTableData';
import TransparenceTableSelectors from '@/store/ducks/transparenceTable/selectors';
import { transparenceDataActions } from '@/store/ducks/transparenceTable';
// import { filtersActions } from '@/store/actions';
// import { RoutesMap } from '@/routers/map';
import TransparenceTableLoading from '@/components/loadings/TransparenceTableLoading';

const TransparenceTable: React.FC = () => {
  const data = useSelector(TransparenceTableSelectors.getTransparenceTableData);
  const loadingTable = useSelector(TransparenceTableSelectors.getLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(filtersActions.filterRequest(RoutesMap.TRANSPARENCE_TABLE));
    dispatch(transparenceDataActions.transparenceTableRequest());
  }, [dispatch]);
  return (
    <Box
      component="div"
      sx={{
        height: '100%',
        padding: '30px',
        display: 'flex',
        alignItems: loadingTable ? 'center' : 'flex-start',
      }}
    >
      {loadingTable ? (
        <TransparenceTableLoading />
      ) : (
        <TransparenceTableData data={data} />
      )}
    </Box>
  );
};

export default TransparenceTable;
