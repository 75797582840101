import * as types from './types';

export const myReportsRequest = (data: {
  brand_id?: string;
  days?: string;
}) => {
  return {
    type: types.MY_REPORTS_REQUEST,
    data,
  };
};
export const myReportsOverallRequest = () => {
  return {
    type: types.MY_REPORTS_OVERALL_REQUEST,
  };
};
export const myReportsPlatformRequest = () => {
  return {
    type: types.MY_REPORTS_PLATFORM_REQUEST,
  };
};
export const myReportsBreakdownScoreRequest = () => {
  return {
    type: types.MY_REPORTS_BREAKDOWN_SCORE_REQUEST,
  };
};
export const myReportsHeatMapRequest = () => {
  return {
    type: types.MY_REPORTS_HEATMAP_REQUEST,
  };
};
export const myReportsCompetitorEvolutionRequest = () => {
  return {
    type: types.MY_REPORTS_COMPETITOR_EVOLUTION_REQUEST,
  };
};
export const myReportsSocialMediaFeelingRequest = () => {
  return {
    type: types.MY_REPORTS_SOCIAL_MEDIA_FEELING_REQUEST,
  };
};
export const myReportsSocialMediaOverallRequest = () => {
  return {
    type: types.MY_REPORTS_SOCIAL_MEDIA_OVERALL_REQUEST,
  };
};

export const updateMyReportsSuccess = (data) => {
  return {
    type: types.MY_REPORTS_SUCCESS,
    payload: { data },
  };
};
export const updateMyReportsOverallSuccess = (data) => {
  return {
    type: types.MY_REPORTS_OVERALL_SUCCESS,
    payload: { data },
  };
};
export const updateMyReportsPlatformSuccess = (data) => {
  return {
    type: types.MY_REPORTS_PLATFORM_SUCCESS,
    payload: { data },
  };
};
export const updateMyReportsBreakdownScoreSuccess = (data) => {
  return {
    type: types.MY_REPORTS_BREAKDOWN_SCORE_SUCCESS,
    payload: { data },
  };
};
export const updateMyReportsHeatMapSuccess = (data) => {
  return {
    type: types.MY_REPORTS_HEATMAP_SUCCESS,
    payload: { data },
  };
};
export const updateMyReportsCompetitorEvolutionSuccess = (data) => {
  return {
    type: types.MY_REPORTS_COMPETITOR_EVOLUTION_SUCCESS,
    payload: { data },
  };
};
export const updateMyReportsSocialMediaFeelingSuccess = (data) => {
  return {
    type: types.MY_REPORTS_SOCIAL_MEDIA_FEELING_SUCCESS,
    payload: { data },
  };
};
export const updateMyReportsSocialMediaOverallSuccess = (data) => {
  return {
    type: types.MY_REPORTS_SOCIAL_MEDIA_OVERALL_SUCCESS,
    payload: { data },
  };
};

export const updateMyReportsFailure = () => {
  return {
    type: types.MY_REPORTS_FAILURE,
  };
};
export const updateMyReportsOverallFailure = () => {
  return {
    type: types.MY_REPORTS_OVERALL_FAILURE,
  };
};
export const updateMyReportsPlatformFailure = () => {
  return {
    type: types.MY_REPORTS_PLATFORM_FAILURE,
  };
};
export const updateMyReportsBreakdownScoreFailure = () => {
  return {
    type: types.MY_REPORTS_BREAKDOWN_SCORE_FAILURE,
  };
};
export const updateMyReportsHeatMapFailure = () => {
  return {
    type: types.MY_REPORTS_HEATMAP_FAILURE,
  };
};
export const updateMyReportsCompetitorEvolutionFailure = () => {
  return {
    type: types.MY_REPORTS_COMPETITOR_EVOLUTION_FAILURE,
  };
};
export const updateMyReportsSocialMediaFeelingFailure = () => {
  return {
    type: types.MY_REPORTS_SOCIAL_MEDIA_FEELING_FAILURE,
  };
};
export const updateMyReportsSocialMediaOverallFailure = () => {
  return {
    type: types.MY_REPORTS_SOCIAL_MEDIA_OVERALL_FAILURE,
  };
};

const myReportsActions = {
  myReportsRequest,
  myReportsOverallRequest,
  myReportsBreakdownScoreRequest,
  myReportsCompetitorEvolutionRequest,
  myReportsHeatMapRequest,
  myReportsPlatformRequest,
  myReportsSocialMediaFeelingRequest,
  myReportsSocialMediaOverallRequest,
  updateMyReportsSuccess,
  updateMyReportsOverallSuccess,
  updateMyReportsBreakdownScoreSuccess,
  updateMyReportsCompetitorEvolutionSuccess,
  updateMyReportsHeatMapSuccess,
  updateMyReportsPlatformSuccess,
  updateMyReportsSocialMediaFeelingSuccess,
  updateMyReportsSocialMediaOverallSuccess,
  updateMyReportsFailure,
  updateMyReportsOverallFailure,
  updateMyReportsBreakdownScoreFailure,
  updateMyReportsCompetitorEvolutionFailure,
  updateMyReportsHeatMapFailure,
  updateMyReportsPlatformFailure,
  updateMyReportsSocialMediaFeelingFailure,
  updateMyReportsSocialMediaOverallFailure,
};

export default myReportsActions;
