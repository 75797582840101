import { RootState } from '@/store';

const getMyReportsData = (state: RootState) => state.myReports.data;

const getOverallLoading = (state: RootState) => state.myReports.overallLoading;
const getPlatformLoading = (state: RootState) =>
  state.myReports.plataformLoading;
const getBreakdownScoreLoading = (state: RootState) =>
  state.myReports.breakdownScoreLoading;
const getHeatMapLoadingoading = (state: RootState) =>
  state.myReports.heatMapLoading;
const getCompetitorEvolutionLoading = (state: RootState) =>
  state.myReports.competitorEvolutionLoading;
const getSocialMediaFeelingLoading = (state: RootState) =>
  state.myReports.socialMediaPillarLoading;
const getSocialMediaOverallLoading = (state: RootState) =>
  state.myReports.socialMediaOverallLoading;

const MyReportsSelectors = {
  getOverallLoading,
  getPlatformLoading,
  getBreakdownScoreLoading,
  getHeatMapLoadingoading,
  getCompetitorEvolutionLoading,
  getSocialMediaFeelingLoading,
  getSocialMediaOverallLoading,
  getMyReportsData,
};

export default MyReportsSelectors;
