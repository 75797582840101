import { fork } from 'redux-saga/effects';
import watchUserAuthentication from '../ducks/auth/sagas';
import watchUpload from '../ducks/upload/sagas';
import watchFilter from '../ducks/filters/sagas';
import watchTransparenceTable from '../ducks/transparenceTable/sagas';
import watchMyReports from '../ducks/myReports/sagas';
import watchAlertManagement from '../ducks/alertManagement/sagas';
import watchDeiRanking from '../ducks/deiRanking/sagas';

export default function* startForman() {
  yield fork(watchUserAuthentication);
  yield fork(watchUpload);
  yield fork(watchTransparenceTable);
  yield fork(watchMyReports);
  yield fork(watchFilter);
  yield fork(watchAlertManagement);
  yield fork(watchDeiRanking);
}
