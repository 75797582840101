import React from 'react';

import { Card, CardContent, Grid, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  ResponsiveContainer,
  YAxis,
} from 'recharts';

import Heatmap from '../Heatmap';
import { HeatMapProps } from '@/lib/models/charts';
import { colorHeatmap } from '@/lib/utils/functions';
import Strings from '@/lib/constants/values/strings.json';

const CustomizedLabel = (props: any) => {
  const { x, y, width, height, value } = props;
  const fireOffset = value.toString().length < 3;
  const offset = fireOffset ? -17 : 5;
  return (
    <text
      fontSize="0.75rem"
      x={x + width - offset}
      y={y + height - 5.5}
      fill="#000"
      textAnchor="end"
      fontWeight="700"
    >
      {value}
    </text>
  );
};

const CardHeatmap = ({ data }: HeatMapProps) => {
  return (
    <Card>
      <CardContent
        sx={{
          padding: '24px 27px',
          minHeight: '369px',
        }}
      >
        <Typography variant="subtitle1">
          {Strings.charts.titleHeatmap}
        </Typography>
        {data && (
          <Grid container alignItems="center" justifyContent="center" mt={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Heatmap data={data} />
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <ResponsiveContainer width={'100%'} height={261}>
                <BarChart data={data} layout="vertical">
                  <XAxis hide axisLine={false} type="number" />
                  <YAxis
                    type="category"
                    dataKey="country"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontWeight: '300',
                      fontSize: '0.75rem',
                      fill: '#000',
                    }}
                  />
                  <Bar
                    dataKey="value"
                    barSize={20}
                    background={{ fill: '#E5E5E5' }}
                    label={<CustomizedLabel />}
                  >
                    {data.map((entry) => (
                      <Cell fill={colorHeatmap(entry.value)} key={entry.id} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default CardHeatmap;
