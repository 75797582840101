import api from './api';

export const alertManagementRequest = () => api.get('post/alert-management');

export const alertManagementNotificationsRequest = (data: {
  id: string;
  days: string;
}) =>
  api.get(`post/notifications`, {
    params: { days: data.days, brand_id: data.id },
  });
