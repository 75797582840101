import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Logo } from './styles';
import { loginImg, logoDark } from '@/lib/constants/imgs';
import Strings from '@/lib/constants/values/strings.json';
import { authActions } from '@/store/actions';
import { authSelectors } from '@/store/selectors';

interface IFormInputs {
  username: string;
  password: string;
}

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

export default function SignInSide() {
  const dispatch = useDispatch();

  const loading = useSelector(authSelectors.getLoading);
  const [remember, setRemember] = React.useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) =>
    dispatch(authActions.authRequest(data));

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        component={Paper}
        elevation={6}
        square
        position="relative"
      >
        <Box
          sx={{
            marginTop: 20,
            mx: 5,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              marginBottom: '80px',
            }}
          >
            <Logo src={logoDark} alt="Logo Dei-Brand" loading="lazy" />
            <Typography
              component="span"
              variant="h3"
              sx={{
                marginTop: '18px',
              }}
            >
              DE&I Brand Index
            </Typography>
          </Box>
          <Typography
            component="p"
            sx={{
              fontSize: '1.125rem',
              marginBottom: '40px',
              fontWeight: '300',
            }}
          >
            {Strings.loginAccount}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="username"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  label={Strings.user}
                  autoFocus
                  error={!!errors.username}
                  helperText={errors?.username?.message}
                />
              )}
            />
            <Controller
              name="password"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  label={Strings.password}
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                />
              )}
            />
            <Grid
              container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={remember}
                    color="primary"
                    onChange={() => setRemember(!remember)}
                  />
                }
                label="Remember"
              />
              <Link
                href="#"
                variant="body2"
                sx={{ lineHeight: '48px', color: '#000', fontWeight: '300' }}
              >
                {Strings.forgotPassword}
              </Link>
            </Grid>
            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, height: '56px', width: '115px' }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={25} />
                ) : (
                  <>{Strings.signIn}</>
                )}
              </Button>
            </Box>
          </Box>
        </Box>
        <Grid
          item
          sx={{
            position: 'absolute',
            bottom: '20px',
            textAlign: 'right',
            marginLeft: -5,
            width: '100%',
          }}
        >
          <Link href="#" variant="body2" fontWeight="300">
            {Strings.needHelp}
          </Link>
        </Grid>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: `url(${loginImg})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'right',
        }}
      />
    </Grid>
  );
}
