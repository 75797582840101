import React from 'react';
import { TableCell, TableRow, TableHead, Typography } from '@mui/material';

export default function TableHeadPagination() {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell sx={{ border: 'none' }} />
          <TableCell
            align="center"
            sx={{
              border: 'none',
              borderLeft: '1px solid #E5E5E5',
            }}
          />
          <TableCell
            align="center"
            sx={{
              border: 'none',
              borderLeft: '1px solid #E5E5E5',
            }}
          />
          <TableCell
            align="center"
            color="secondary"
            colSpan={3}
            sx={{
              border: 'none',
              borderLeft: '1px solid #E5E5E5',
            }}
          >
            <Typography
              variant="h6"
              component="p"
              color="secondary"
              align="center"
              sx={{
                fontSize: 16,
              }}
            >
              RACE
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            sx={{ border: 'none', borderLeft: '1px solid #E5E5E5' }}
          >
            <Typography
              variant="h6"
              component="p"
              color="secondary"
              align="center"
              sx={{
                fontSize: 16,
              }}
            >
              SEXUAL ORIENTATION
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            colSpan={2}
            sx={{ border: 'none', borderLeft: '1px solid #E5E5E5' }}
          >
            <Typography
              variant="h6"
              component="p"
              color="secondary"
              align="center"
              sx={{
                fontSize: 16,
              }}
            >
              GENDER
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            colSpan={3}
            sx={{ border: 'none', borderLeft: '1px solid #E5E5E5' }}
          >
            <Typography
              variant="h6"
              component="p"
              color="secondary"
              align="center"
              sx={{
                fontSize: 16,
              }}
            >
              AGE
            </Typography>
          </TableCell>
          <TableCell
            align="center"
            colSpan={2}
            sx={{ border: 'none', borderLeft: '1px solid #E5E5E5' }}
          >
            <Typography
              variant="h6"
              component="p"
              color="secondary"
              align="center"
              sx={{
                fontSize: 16,
              }}
            >
              CORMOBIDITY
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow color="primary.contrastText" sx={{ height: '55px' }}>
          <TableCell sx={{ fontSize: 16 }}>Social Media Link</TableCell>
          <TableCell
            align="center"
            sx={{ borderLeft: '1px solid #E5E5E5', fontSize: 16 }}
          >
            Date
          </TableCell>
          <TableCell
            align="center"
            sx={{ borderLeft: '1px solid #E5E5E5', fontSize: 16 }}
          >
            Channel
          </TableCell>
          <TableCell
            align="center"
            sx={{ borderLeft: '1px solid #E5E5E5', fontSize: 16 }}
          >
            White
          </TableCell>
          <TableCell align="center" sx={{ fontSize: 16 }}>
            Black
          </TableCell>
          <TableCell align="center" sx={{ fontSize: 16 }}>
            Asian
          </TableCell>
          <TableCell
            align="center"
            sx={{ borderLeft: '1px solid #E5E5E5', fontSize: 16 }}
          >
            LGBTQIA+
          </TableCell>
          <TableCell
            align="center"
            sx={{ borderLeft: '1px solid #E5E5E5', fontSize: 16 }}
          >
            Male
          </TableCell>
          <TableCell align="center" sx={{ fontSize: 16 }}>
            Female
          </TableCell>
          <TableCell
            align="center"
            sx={{ borderLeft: '1px solid #E5E5E5', fontSize: 16 }}
          >
            Children
          </TableCell>
          <TableCell align="center" sx={{ fontSize: 16 }}>
            Adult
          </TableCell>
          <TableCell align="center" sx={{ fontSize: 16 }}>
            Elder
          </TableCell>
          <TableCell
            align="center"
            sx={{ borderLeft: '1px solid #E5E5E5', fontSize: 16 }}
          >
            Physical
          </TableCell>
          <TableCell align="center" sx={{ fontSize: 16 }}>
            Pwd
          </TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}
