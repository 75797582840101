import React from 'react';
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

const DeiBrandRankingLoading: React.FC = () => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" scope="row" colSpan={3}>
              <Skeleton
                sx={{
                  transform: 'unset',
                }}
                width="100%"
                height={150}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
              scope="row"
            >
              DIVERSITY PILAR
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
              scope="row"
            >
              RANK
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: 16,
                fontWeight: 300,
                color: 'secondary.main',
              }}
              scope="row"
            >
              SCORE
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }, () => Math.floor(Math.random() * 5))?.map(
            (row, key) => (
              <TableRow key={key}>
                <TableCell scope="row">
                  <Skeleton
                    sx={{
                      transform: 'unset',
                    }}
                    width="100%"
                    height={25}
                  />
                </TableCell>
                <TableCell scope="row">
                  <Skeleton
                    sx={{
                      transform: 'unset',
                    }}
                    width="100%"
                    height={25}
                  />
                </TableCell>
                <TableCell scope="row">
                  <Skeleton
                    sx={{
                      transform: 'unset',
                    }}
                    width="100%"
                    height={25}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeiBrandRankingLoading;
