import { LinearProgress, linearProgressClasses } from '@mui/material';

import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  width: '100%',
  borderRadius: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D7D7D7',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#5D5FEF',
  },
}));

export default BorderLinearProgress;
