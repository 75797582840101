import { put, takeLatest, call, fork, take } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';

import toast from 'react-hot-toast';
import history from '@/lib/utils/history';
import * as upload from '@/services/upload';
import * as actions from './actions';
import * as types from './types';
import Strings from '@/lib/constants/values/strings.json';
import AuthActions from '../auth/actions';

function uploadFile(payload: File, onProgress: any) {
  const data = new FormData();

  data.append('source', payload, payload.name);

  const config = {
    onUploadProgress: onProgress,
  };

  return upload.uploadTemplate(data, config);
}

function createUploader(file: File) {
  let emit: any;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit({ percentage, loaded, total });
    if (percentage === 100) emit(END);
  };
  const uploadPromise = uploadFile(file, uploadProgressCb);
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan: any) {
  while (true) {
    const progress = yield take(chan);
    yield put(actions.setProgress(progress));
  }
}

function* uploadSource(payload: any) {
  const { file } = payload;
  yield put(actions.setLoading());
  try {
    const [uploadPromise, chan] = yield call(createUploader, file);
    yield fork(uploadProgressWatcher, chan);
    const response = yield call(() => uploadPromise);

    if (response.data.success) {
      yield put(AuthActions.setTemplateIsLoaded(true));
      history.replace('/');
    } else {
      toast.error(response.data.message);
    }
  } catch (e) {
    toast.error(Strings.errorApi);
  }
  yield put(actions.resetProgress());
  yield put(actions.setLoading());
}

export default function* watchUpload() {
  yield takeLatest(types.UPLOAD_TEMPLATE, uploadSource);
}
