export const ALERT_MANAGEMENT_REQUEST: string =
  'alertManagement/ALERT_MANAGEMENT_REQUEST';
export const ALERT_MANAGEMENT_SUCCESS: string =
  'alertManagement/ALERT_MANAGEMENT_SUCCESS';
export const ALERT_MANAGEMENT_FAILURE: string =
  'alertManagement/ALERT_MANAGEMENT_FAILURE';
export const ALERT_MANAGEMENT_NOTIFICATIONS_REQUEST: string =
  'alertManagement/ALERT_MANAGEMENT_NOTIFICATIONS_REQUEST';
export const ALERT_MANAGEMENT_NOTIFICATIONS_SUCCESS: string =
  'alertManagement/ALERT_MANAGEMENT_NOTIFICATIONS_SUCCESS';

const AlertTypes = {
  ALERT_MANAGEMENT_REQUEST,
  ALERT_MANAGEMENT_SUCCESS,
  ALERT_MANAGEMENT_FAILURE,
  ALERT_MANAGEMENT_NOTIFICATIONS_REQUEST,
  ALERT_MANAGEMENT_NOTIFICATIONS_SUCCESS,
};

export default AlertTypes;
