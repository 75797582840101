import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageNotFound from '@/pages/PageNotFound';
import routeWrapper from './Route';
import routes from './routes';

const Router = () => {
  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} {...routeWrapper(route)} />
      ))}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
